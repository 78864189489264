<template>
    <span>
        <span @click="startConfirmingPassword">
            <slot />
        </span>

        <Modal v-if="confirmingPassword" size="md" @close="closeModal">
            <template #header>
                <div class="text-gray-600">{{ title }}</div>
            </template>

            <template #body>
                <div class="my-6 w-full px-6">
                    <p class="pb-3 text-sm text-gray-500">
                        {{ content }}
                    </p>
                    <TextInput
                        id="password"
                        ref="password"
                        v-model="state.form.password"
                        autofocus
                        type="password"
                        inputmode="numeric"
                        :error="getError('password')"
                        @keyup.enter="confirmPassword"
                    />
                </div>
            </template>

            <template #footer>
                <div class="flex justify-between border-t border-gray-200 p-6">
                    <AtomicButton color="white" size="md" @click="closeModal">
                        Cancel
                    </AtomicButton>
                    <AtomicButton
                        color="yellow"
                        size="md"
                        :class="{ 'opacity-25': state.form.processing }"
                        :disabled="state.form.processing"
                        @click="confirmPassword"
                    >
                        {{ button }}
                    </AtomicButton>
                </div>
            </template>
        </Modal>
    </span>
</template>

<script lang="ts" setup>
import axios from "axios";
import route from "ziggy-js";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import IInputErrors from "@/types/Atomic/IInputErrors";

defineProps({
    title: {
        type: String,
        default: "Confirm Password",
    },
    content: {
        type: String,
        default: "For your security, please confirm your password to continue.",
    },
    button: {
        type: String,
        default: "Confirm",
    },
});

const emit = defineEmits(["confirmed"]);

const confirmingPassword = ref(false);
const errors = ref<IInputErrors>({});

const state = reactive({
    form: {
        processing: false,
        password: "",
    },
});

const rules = {
    form: {
        password: {
            required,
        },
    },
};

const v$ = useVuelidate(rules, state);

const startConfirmingPassword = () => {
    axios.get(route("password.confirmation")).then((response) => {
        if (response.data.confirmed) {
            emit("confirmed");
        } else {
            confirmingPassword.value = true;
        }
    });
};

const confirmPassword = () => {
    v$.value.form.$touch();
    if (v$.value.form.$error) {
        () => undefined;
    } else {
        state.form.processing = true;

        axios
            .post(route("password.confirm"), {
                password: state.form.password,
            })
            .then(() => {
                state.form.processing = false;
                closeModal();
                nextTick(() => emit("confirmed"));
            })
            .catch((error) => {
                state.form.processing = false;
                errors.value = error.response.data.errors.password[0];
            });
    }
};

const closeModal = () => {
    confirmingPassword.value = false;
};

const getError = (field: string) => {
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ""
    );
};
</script>
