<template>
    <div class="my-10">
        <div class="flex justify-between">
            <H1Header> Countdowns </H1Header>

            <AtomicButton @click="createNew">
                <template #icon>
                    <PlusCircleIcon class="mr-2 h-6 w-6" />
                </template>
                Create New
            </AtomicButton>
        </div>

        <div class="mt-5">
            <div class="grid grid-cols-1 gap-4 sm:grid-cols-4">
                <CountdownCard
                    v-for="countdown in countdowns"
                    :key="countdown.id"
                    :countdown="countdown"
                />
            </div>

            <EmptyState
                v-if="countdowns.length === 0"
                title="There are no countdowns yes"
                @submit="createNew"
            >
                <template #icon>
                    <ClockIcon />
                </template>
            </EmptyState>
        </div>
    </div>
</template>

<script lang="ts" setup>
import ICountdown from "@/types/Countdowns/ICountdown";
import { ClockIcon, PlusCircleIcon } from "@heroicons/vue/24/outline";
import { PropType } from "vue";

defineProps({
    countdowns: {
        type: Array as PropType<ICountdown[]>,
        required: true,
    },
});

const createNew = () => {
    () => undefined;
};
</script>
