<template>
    <div class="flex items-center justify-center">
        <span class="relative inline-flex text-white">
            <button class="mx-6" @click.stop="toggleAnnouncements">
                <svg
                    class="relative"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect
                        width="32"
                        height="32"
                        rx="16"
                        class="duration-50 transition-all ease-out"
                        :fill="!announcementsOpened ? '#374151' : '#FFC000'"
                    />
                    <path
                        d="M17 7V14H24L15 25V18H8L17 7Z"
                        class="transition-all duration-100 ease-out"
                        :fill="!announcementsOpened ? '#FFC000' : '#374151'"
                        :stroke="!announcementsOpened ? '#FFC000' : '#374151'"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                    <circle
                        cx="27"
                        cy="5"
                        r="4"
                        fill="#FFC000"
                        stroke="#252F3F"
                        stroke-width="2"
                    />
                </svg>
            </button>

            <span
                class="absolute flex items-center justify-center"
                :class="[
                    hasUnreadAnnouncements
                        ? 'right-[24px] top-[0px] h-2.5 w-2.5'
                        : 'right-[30px] top-1.5 -mr-1 -mt-1 h-1.5 w-1.5',
                ]"
            >
                <span
                    class="absolute inline-flex h-full w-full rounded-full bg-orange-500 opacity-100"
                    :class="{ 'animate-ping': hasUnreadAnnouncements }"
                ></span>
                <span
                    class="relative inline-flex h-1.5 w-1.5 rounded-full bg-orange-500"
                ></span>
            </span>
        </span>
    </div>
</template>

<script lang="ts" setup>
defineProps<{
    announcementsOpened: boolean;
    hasUnreadAnnouncements: boolean;
}>();

const emit = defineEmits(["toggleAnnouncements"]);

const toggleAnnouncements = () => {
    emit("toggleAnnouncements");
};
</script>
