<template>
    <div class="relative flex">
        <!--    To turn off autocomplete when otp-input is password-->
        <input
            v-if="inputType === 'password'"
            autocomplete="off"
            name="hidden"
            type="text"
            style="display: none"
        />
        <SingleOtpInput
            v-for="(item, i) in numInputsArray"
            :key="i"
            :focus="activeInput === i"
            :value="otp[i]"
            :separator="separator"
            :input-type="inputType"
            :input-classes="inputClasses"
            :is-third-child="i === 2"
            :is-fourth-child="i === 3"
            :is-last-child="i === numInputs - 1"
            :should-auto-focus="shouldAutoFocus"
            @on-change="handleOnChange"
            @on-keydown="handleOnKeyDown"
            @on-focus="handleOnFocus(i)"
            @on-blur="handleOnBlur"
        />

        <div
            v-if="error"
            class="absolute -bottom-10 left-2 text-sm text-custom-red-600"
        >
            {{ error }}
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";
// keyCode constants
const BACKSPACE = 8;
const LEFT_ARROW = 37;
const RIGHT_ARROW = 39;
const DELETE = 46;

const props = defineProps({
    error: {
        type: String,
        default: "",
    },
    numInputs: {
        type: Number,
        default: 6,
    },
    separator: {
        type: String,
        default: "**",
    },
    inputClasses: {
        type: String,
        default: "",
    },
    inputType: {
        type: String,
        validator: (value: string) =>
            ["number", "tel", "password"].includes(value),
        default: "tel",
    },
    shouldAutoFocus: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(["on-complete", "on-change"]);

const activeInput = ref<number>(0);
const otp = ref<[]>([]);
const oldOtp = ref<[]>([]);
const handleOnFocus = (index: number) => {
    activeInput.value = index;
};
const handleOnBlur = () => {
    activeInput.value = -1;
};
const numInputsArray = computed(() => {
    let array = [];

    for (var i = 1; i <= props.numInputs; i++) {
        array.push(i);
    }
    return array;
}).value;
// Helper to return OTP from input
const checkFilledAllInputs = () => {
    if (otp.value.join("").length === props.numInputs) {
        return emit("on-complete", otp.value.join(""));
    }
    return "Wait until the user enters the required number of characters";
};
// Focus on input by index
const focusInput = (input: number) => {
    activeInput.value = Math.max(Math.min(props.numInputs - 1, input), 0);
};
// Focus on next input
const focusNextInput = () => {
    focusInput(activeInput.value + 1);
};
// Focus on previous input
const focusPrevInput = () => {
    focusInput(activeInput.value - 1);
};
// Change OTP value at focused input
const changeCodeAtFocus = (value: number | string) => {
    oldOtp.value = Object.assign([], otp.value);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    otp.value[activeInput.value] = value;
    if (oldOtp.value.join("") !== otp.value.join("")) {
        emit("on-change", otp.value.join(""));
        checkFilledAllInputs();
    }
};
const handleOnChange = (value: number) => {
    changeCodeAtFocus(value);
    focusNextInput();
};
// Handle cases of backspace, delete, left arrow, right arrow
const handleOnKeyDown = (event: KeyboardEvent) => {
    switch (event.keyCode) {
        case BACKSPACE:
            event.preventDefault();
            changeCodeAtFocus("");
            focusPrevInput();
            break;
        case DELETE:
            event.preventDefault();
            changeCodeAtFocus("");
            break;
        case LEFT_ARROW:
            event.preventDefault();
            focusPrevInput();
            break;
        case RIGHT_ARROW:
            event.preventDefault();
            focusNextInput();
            break;
        default:
            break;
    }
};
</script>

<style scoped></style>
