import { IMailClockerPageProps } from "@/types/MailClockerPageProps/MailClockerPageProps";
import { usePage } from "@inertiajs/vue3";
import { computed } from "vue";

export default () => {
    const inertiaProps = usePage<IMailClockerPageProps>();

    const isViewer = computed(() => {
        return inertiaProps.props.team_role.key === "viewer";
    });

    const isEditor = computed(() => {
        return inertiaProps.props.team_role.key === "editor";
    });

    const isAdmin = computed(() => {
        return inertiaProps.props.team_role.key === "admin";
    });

    const isOwner = computed(() => {
        return inertiaProps.props.team_role.key === "owner";
    });

    return {
        isViewer,
        isEditor,
        isAdmin,
        isOwner,
    };
};
