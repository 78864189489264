<template>
    <div ref="tableDropdownComponent">
        <div ref="tableDropdownButton" @click="toggle()">
            <slot name="dropdown-button"></slot>
        </div>
        <div ref="tableDropdownContent" class="absolute left-0 top-0 z-10">
            <transition
                enter-active-class="transition duration-100 ease-out"
                enter-class="transform scale-75 opacity-0"
                enter-to-class="transform scale-100 opacity-100"
                leave-active-class="transition duration-75 ease-in"
                leave-class="transform scale-100 opacity-100 "
                leave-to-class="transform scale-75 opacity-0"
            >
                <div v-show="isOpen" class="w-full rounded-md shadow-lg">
                    <div
                        role="menu"
                        aria-orientation="vertical"
                        aria-labelledby="options-menu"
                    >
                        <slot name="dropdown-content"></slot>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script lang="ts" setup>
import {
    computePosition,
    autoUpdate,
    flip,
    shift,
    limitShift,
    offset,
} from "@floating-ui/vue";

const props = defineProps({
    placement: {
        type: String,
        default: "bottom-end",
    },
    isOpen: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(["close", "open"]);
const tableDropdownButton = ref<Element | null>(null);
const tableDropdownContent = ref<Element | null>(null);
const tableDropdownComponent = ref<Element | null>(null);

onMounted(() => {
    document.addEventListener("click", close);
    updatePosition();
    cleanup();
});

const updatePosition = () => {
    if (tableDropdownButton.value && tableDropdownContent.value) {
        computePosition(
            tableDropdownButton.value,
            tableDropdownContent.value as HTMLElement,
            {
                placement: "bottom-start",
                middleware: [
                    flip(),
                    shift({ limiter: limitShift() }),
                    offset(4),
                ],
            }
        ).then(({ x, y }) => {
            Object.assign(tableDropdownContent.value?.style, {
                left: `${x}px`,
                top: `${y}px`,
            });
        });
    }
};

const cleanup = () => {
    if (tableDropdownButton.value && tableDropdownContent.value) {
        autoUpdate(
            tableDropdownButton.value,
            tableDropdownContent.value as HTMLElement,
            updatePosition
        );
    }
};

const close = (event: Event) => {
    if (
        tableDropdownComponent.value &&
        !tableDropdownComponent.value.contains(event.target as Node)
    ) {
        emit("close");
    }
};

const toggle = () => {
    if (props.isOpen) {
        emit("close");
    } else {
        emit("open");
    }
};
</script>

<style></style>
