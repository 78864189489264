<template>
    <div>
        <Action class="focus:outline-none" :items="items">
            <template #activator="{ onClick }">
                <div
                    class="mt-[6px] h-[30px] w-[30px] cursor-pointer rounded-full ring-[1px] ring-gray-300"
                >
                    <img
                        :src="profilePhoto"
                        class="h-[30px] w-[30px] rounded-full object-cover"
                        @click="onClick"
                    />
                </div>
            </template>
        </Action>
        <CreateTeamModal
            v-if="createTeamModal"
            @close="createTeamModal = false"
            @close-and-switch-to-team="handleCloseAndSwitchToTeam"
        />
    </div>
</template>

<script lang="ts" setup>
import { ref, computed } from "vue";
import { router } from "@inertiajs/vue3";
import { usePage } from "@inertiajs/vue3";
import route from "ziggy-js";
import { PlusCircleIcon } from "@heroicons/vue/24/outline";
import IActionNode from "@/types/Atomic/IActions";
import IUser from "@/types/Members/IUser";

const { getCurrentTeamMembersCount } = useTeamsAndMembers();

let createTeamModal = ref(false);

const isAdmin = computed(() => usePage().props.isAdmin);

const handleCloseAndSwitchToTeam = () => {
    createTeamModal.value = false;
    handleFetchBannersAndMembers();
};

const handleFetchBannersAndMembers = () => {
    getCurrentTeamMembersCount();
};

const items: IActionNode[] = [
    {
        name: "Dashboard",
        onClick() {
            router.visit(route("home"), {
                onSuccess: () => {
                    router.reload();
                },
            });
        },
    },
    {
        name: "Create new team",
        icon: PlusCircleIcon,
        onClick() {
            createTeamModal.value = true;
        },
    },
    {
        name: "Preferences",
        onClick() {
            router.visit(route("settings.profile-settings.index"), {
                onSuccess: () => {
                    router.reload();
                },
            });
        },
    },
    {
        name: "Logout",
        onClick() {
            router.post(route("logout"));
        },
    },
];

if (isAdmin.value) {
    items.splice(2, 0, {
        name: "Kiosk",
        onClick() {
            router.visit(route("kiosk.users.index"));
        },
    });
}

const profilePhoto = computed(() => {
    const user = usePage().props.user as IUser;
    return user.profile_photo_url;
});
</script>
