<template>
    <div
        v-click-away="onClickAway"
        class="relative inline-block text-left"
        tabindex="0"
        @keydown.esc="isOpen = false"
    >
        <AtomicButton
            v-if="activatorType === 'button' && !hasActivatorSlot"
            color="white"
            @click="toggleOpen"
        >
            {{ activatorText }}

            <div class="ml-4">
                <svg
                    class="h-[14px] w-1"
                    width="4"
                    height="14"
                    viewBox="0 0 4 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M2.00008 2.00008V2.00842M2.00008 7.00008V7.00842M2.00008 12.0001V12.0084M2.00008 2.83341C1.77907 2.83341 1.56711 2.74562 1.41083 2.58934C1.25455 2.43306 1.16675 2.2211 1.16675 2.00008C1.16675 1.77907 1.25455 1.56711 1.41083 1.41083C1.56711 1.25455 1.77907 1.16675 2.00008 1.16675C2.2211 1.16675 2.43306 1.25455 2.58934 1.41083C2.74562 1.56711 2.83342 1.77907 2.83342 2.00008C2.83342 2.2211 2.74562 2.43306 2.58934 2.58934C2.43306 2.74562 2.2211 2.83341 2.00008 2.83341ZM2.00008 7.83342C1.77907 7.83342 1.56711 7.74562 1.41083 7.58934C1.25455 7.43306 1.16675 7.2211 1.16675 7.00008C1.16675 6.77907 1.25455 6.56711 1.41083 6.41083C1.56711 6.25455 1.77907 6.16675 2.00008 6.16675C2.2211 6.16675 2.43306 6.25455 2.58934 6.41083C2.74562 6.56711 2.83342 6.77907 2.83342 7.00008C2.83342 7.2211 2.74562 7.43306 2.58934 7.58934C2.43306 7.74562 2.2211 7.83342 2.00008 7.83342ZM2.00008 12.8334C1.77907 12.8334 1.56711 12.7456 1.41083 12.5893C1.25455 12.4331 1.16675 12.2211 1.16675 12.0001C1.16675 11.7791 1.25455 11.5671 1.41083 11.4108C1.56711 11.2545 1.77907 11.1667 2.00008 11.1667C2.2211 11.1667 2.43306 11.2545 2.58934 11.4108C2.74562 11.5671 2.83342 11.7791 2.83342 12.0001C2.83342 12.2211 2.74562 12.4331 2.58934 12.5893C2.43306 12.7456 2.2211 12.8334 2.00008 12.8334Z"
                        stroke="#9FA6B2"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>
        </AtomicButton>

        <ButtonDots
            v-if="activatorType === 'dots' && !hasActivatorSlot"
            size="xs"
            @onClick="toggleOpen"
        >
        </ButtonDots>

        <div v-if="hasActivatorSlot">
            <slot name="activator" :on-click="toggleOpen" :is-open="isOpen" />
        </div>

        <ActionList
            v-if="isOpen"
            :nodes="items"
            @node-clicked="nodeWasClicked"
            @click.stop="toggleOpen"
        />
    </div>
</template>

<script lang="ts" setup>
import { PropType } from "vue";
import IActionNode from "@/types/Atomic/IActions";

const props = defineProps({
    items: {
        type: Array as PropType<IActionNode[]>,
        default: () => [],
    },

    closeEvent: {
        type: Boolean,
        default: false,
    },

    /**
     * Button which actives the dropdown open
     * @values dots, button
     */
    activatorType: {
        type: String,
        default: "button",
    },
    activatorText: {
        type: String,
        default: "",
    },
});
const emit = defineEmits(["click", "opened"]);
const isOpen = ref(false);
const slots = useSlots();

const hasActivatorSlot = computed(() => !!slots.activator);

function onClickAway() {
    isOpen.value = false;
}
function toggleOpen() {
    isOpen.value = !isOpen.value;
    emit("opened", isOpen.value);
}

function nodeWasClicked(node: IActionNode) {
    if (node.onClick !== undefined) {
        node.onClick();
    }
    emit("click", node);
    isOpen.value = false;
}

watch(
    () => props.closeEvent,
    (newValue) => {
        if (newValue === true) {
            isOpen.value = false;
        }
    }
);
</script>
