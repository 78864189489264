<template>
    <ul
        :id="'action-list-' + numb"
        class="absolute z-[99999999] w-[180px] overflow-visible rounded-md border border-gray-300 bg-white py-1 opacity-0 shadow-lg transition-opacity duration-300 focus:outline-none"
    >
        <li v-for="(node, index) in nodes" :key="index" class="h-8">
            <div
                role="button"
                class="group relative mx-[5px] flex h-8 cursor-pointer select-none items-center justify-between rounded pl-[11px] pr-[3px] text-xs font-normal text-gray-500 hover:bg-gray-100 hover:text-gray-700 focus:outline-none"
                :class="{
                    'hover:bg-custom-red-100 hover:text-custom-red-600':
                        node.danger,
                    'bg-gray-100 text-gray-700': isExpanded(node),
                }"
                @mouseenter="nodeHovered(node)"
                @click.stop="clickNode(node)"
            >
                <span class="truncate">{{ node.name }}</span>

                <span v-if="node.isChecked">
                    <CheckIcon class="mr-2 h-4 w-4"></CheckIcon
                ></span>
                <div
                    v-if="node.icon"
                    class="cursor-pointer text-gray-400 group-hover:text-gray-500"
                >
                    <component :is="node.icon" class="h-6 w-6" />
                </div>

                <div
                    v-show="node.children"
                    class="text-gray-400 group-hover:text-gray-700"
                >
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M11 8.5L14.4645 11.9645L11 15.4289"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>
                </div>
            </div>

            <ActionList
                v-if="isExpanded(node) && node.children"
                :nodes="node.children"
                :depth="depth + 1"
                class="-mt-6 rounded-md border border-gray-300 bg-white py-1 shadow-lg"
                @node-clicked="clickNode"
            />
        </li>

        <div
            :id="'supportBox' + numb"
            class="absolute left-0 top-1/2 z-[-1] h-[calc(100%+40px)] w-[calc(100%+20px)] -translate-y-1/2 transform cursor-default"
        ></div>
    </ul>
</template>

<script lang="ts" setup>
import { ref, PropType, onMounted } from "vue";
import IActionNode from "@/types/Atomic/IActions";

import { CheckIcon } from "@heroicons/vue/24/outline";
import { uuid } from "vue-uuid";

const props = defineProps({
    nodes: {
        type: Array as PropType<IActionNode[]>,
        required: true,
    },
    depth: {
        type: Number,
        default: 0,
    },
});

const emit = defineEmits(["node-clicked"]);

const element = ref<HTMLElement | null>(null);
const expanded = ref<IActionNode[]>([]);
const targetIsVisible = ref(false);
const numb = uuid.v4();

onMounted(() => {
    setElementPosition();
    setSupportBoxPosition();
});

onBeforeUnmount(() => {
    if (element.value) {
        element.value.classList.replace("opacity-100", "opacity-0");
    }
});

const setElementPosition = () => {
    element.value = document.querySelector("#action-list-" + numb);

    if (element.value) {
        const bounding = element.value.getBoundingClientRect();

        setElementPositionHorizontally(bounding);
        setElementPositionVertically(bounding);

        element.value.classList.replace("opacity-0", "opacity-100");
    }
};

const setElementPositionHorizontally = (bounding: DOMRect) => {
    if (element.value) {
        if (
            bounding.right + bounding.width >
            (window.innerWidth || document.documentElement.clientWidth)
        ) {
            targetIsVisible.value = true;
            if (props.depth === 0) {
                element.value.classList.add("right-0");
            } else {
                element.value.classList.add("left-[-171px]");
            }
        } else {
            if (props.depth === 0) {
                element.value.classList.add("left-0");
            } else {
                element.value.classList.add("right-[-171px]");
            }
        }
    }
};

const setElementPositionVertically = (bounding: DOMRect) => {
    if (element.value) {
        if (
            bounding.top > bounding.height + 64 &&
            bounding.top + bounding.height >
                (window.innerHeight || document.documentElement.clientHeight)
        ) {
            targetIsVisible.value = true;

            if (props.depth === 0) {
                element.value.classList.add("bottom-[calc(100%+4px)]");
            } else {
                element.value.classList.add("bottom-0", "mb-1");
            }
        } else {
            element.value.classList.add("origin-top-right", "mt-1");
        }
    }
};

const setSupportBoxPosition = () => {
    const supportBox = document.querySelector("#supportBox" + numb);

    if (!supportBox) {
        return;
    }

    if (targetIsVisible.value) {
        supportBox.classList.replace("left-0", "right-0");
    }
    if (props.depth === 0) {
        supportBox.classList.remove("w-[calc(100%+20px)]", "left-0", "right-0");
        supportBox.classList.add(
            "w-[calc(100%+40px)]",
            "-translate-x-1/2",
            "left-1/2"
        );
    }
};

const isExpanded = (node: IActionNode) => {
    return expanded.value.indexOf(node) !== -1;
};

const nodeHovered = (node: IActionNode) => {
    if (!isExpanded(node)) {
        expanded.value.splice(0, 1, node);
    } else {
        expanded.value.splice(expanded.value.indexOf(node));
    }
};
const clickNode = (node: IActionNode) => {
    if (node.children === undefined) {
        emit("node-clicked", node);
    }
};
</script>
