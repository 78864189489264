<template>
    <AuthLayout>
        <div
            class="mt-8 w-full overflow-hidden bg-white p-6 shadow-xl sm:mt-14 sm:max-w-md sm:rounded-lg"
        >
            <div class="mt-[2px] border-b border-gray-200 pb-4">
                <h1 class="text-center text-xl font-bold text-gray-800">
                    Two-factor authentication
                </h1>

                <div class="mt-3 text-center text-sm text-gray-500">
                    <template v-if="!recovery">
                        Two-factor authentication (2FA) is enabled for your
                        account. Open the two-factor authentication app on your
                        device to view your code.
                    </template>

                    <template v-else>
                        Enter one of your emergency recovery codes.
                    </template>
                </div>
            </div>

            <form @submit.prevent="submit">
                <div class="mt-7 flex flex-col items-center">
                    <OtpInput
                        v-if="!recovery"
                        input-classes="mt-2 rounded-md text-center border border-gray-300 mx-1 sm:mx-2 focus:ring-2 focus:ring-offset-2 focus:ring-orange-500 focus:outline-none transition duration-200 ease-in-out"
                        separator=""
                        :num-inputs="6"
                        :should-auto-focus="true"
                        :is-input-num="true"
                        :error="getErrorCode('code')"
                        @on-change="handleOnChange"
                    />

                    <TextInput
                        v-if="recovery"
                        v-model="v$.formRecoveryCode.recovery_code.$model"
                        required
                        suppress-required-icon
                        label="Recovery code"
                        placeholder="Enter your Recovery code"
                        class="mt-0 w-full"
                        :error="getErrorRecoveryCode('recovery_code')"
                    />

                    <MegaButton class="mt-12" :disabled="loading" type="submit"
                        >Verify
                    </MegaButton>

                    <button
                        class="mb-2 mt-6 text-xs font-medium text-custom-blue-500 hover:text-custom-blue-600"
                        @click.prevent="toggleRecovery"
                    >
                        <template v-if="!recovery"
                            >Enter a recovery code</template
                        >

                        <template v-else
                            >Use an regular authentication code
                            instead</template
                        >
                    </button>
                </div>
            </form>
        </div>
    </AuthLayout>
</template>

<script lang="ts" setup>
import { reactive, ref } from "vue";
import route from "ziggy-js";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import { router } from "@inertiajs/vue3";
import IInputErrors from "@/types/Atomic/IInputErrors";

const recovery = ref(false);

const state = reactive({
    formCode: {
        code: "",
    },
    formRecoveryCode: {
        recovery_code: "",
    },
});

const rules = {
    formCode: {
        code: { required, minLength: minLength(6) },
    },
    formRecoveryCode: {
        recovery_code: { required },
    },
};

const loading = ref(false);
const errors = ref<IInputErrors>({});
const v$ = useVuelidate(rules, state);

const toggleRecovery = async () => {
    recovery.value = !recovery.value;

    if (recovery.value) {
        v$.value.formCode.$reset();
        state.formCode.code = "";
    } else {
        v$.value.formRecoveryCode.$reset();
        state.formRecoveryCode.recovery_code = "";
    }
};

const handleOnChange = (value: number) => {
    state.formCode.code = value.toString();
};

const submit = async () => {
    if (recovery.value) {
        submitRecoveryCode();
    } else submitCode();
};

const submitCode = () => {
    v$.value.formCode.$touch();
    if (v$.value.formCode.$error) {
        () => undefined;
    } else {
        loading.value = true;
        router.post(route("two-factor.login"), state.formCode, {
            preserveScroll: true,
            onError: (err) => {
                errors.value = err;
            },
            onFinish: () => {
                loading.value = false;
            },
        });
    }
};

const submitRecoveryCode = () => {
    v$.value.formRecoveryCode.$touch();
    if (v$.value.formRecoveryCode.$error) {
        () => undefined;
    } else {
        loading.value = true;
        router.post(route("two-factor.login"), state.formRecoveryCode, {
            preserveScroll: true,
            onError: (err) => {
                errors.value = err;
            },
            onFinish: () => {
                loading.value = false;
            },
        });
    }
};

const getErrorRecoveryCode = (field: string) => {
    return (
        v$.value.formRecoveryCode[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ""
    );
};

const getErrorCode = (field: string) => {
    return (
        v$.value.formCode[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ""
    );
};
</script>
