<template>
    <div class="mt-10 rounded-md bg-white p-6 shadow-md">
        <div
            class="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between"
        >
            <div class="flex flex-col sm:flex-row sm:items-center">
                <div
                    class="h-20 w-20 shrink-0 cursor-pointer rounded-full ring-[1px] ring-gray-300"
                >
                    <img
                        :src="selectedUser.profile_photo_url"
                        class="h-20 w-20 rounded-full bg-white object-cover"
                    />
                </div>
                <div class="mt-2 flex flex-col sm:ml-10 sm:mt-0">
                    <h2 class="text-2xl font-bold text-gray-800">
                        {{ selectedUser.name }}
                    </h2>
                    <div class="text-xl font-medium text-gray-500">
                        {{ selectedUser.email }}
                    </div>
                </div>
            </div>

            <div
                class="flex flex-col flex-wrap gap-2 sm:flex-row sm:items-center sm:justify-between"
            >
                <div>
                    <AtomicButton
                        size="sm"
                        color="purple"
                        @click="handleImpersonate(selectedUser)"
                    >
                        <template #icon>
                            <EyeIcon class="mr-2 h-6 w-6" />
                        </template>
                        Impersonate
                    </AtomicButton>
                </div>

                <div>
                    <AtomicButton
                        size="sm"
                        color="yellow"
                        @click="handleResetPassword(selectedUser)"
                    >
                        <template #icon>
                            <AtSymbolIcon class="mr-2 h-6 w-6" />
                        </template>
                        Reset password
                    </AtomicButton>
                </div>

                <div>
                    <AtomicButton
                        size="lg"
                        color="red"
                        @click="handleDeleteUser(selectedUser)"
                    >
                        <template #icon>
                            <TrashIcon class="mr-2 h-6 w-6" />
                        </template>
                        Delete user
                    </AtomicButton>
                </div>
            </div>
        </div>

        <div class="mt-5 grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
            <div class="flex flex-col">
                <div class="flex">
                    <div class="text-sm font-medium text-gray-800">ID:</div>

                    <div class="ml-1 text-sm text-gray-500">
                        {{ selectedUser.id }}
                    </div>
                </div>

                <div class="mt-1 flex">
                    <div class="text-sm font-medium text-gray-800">Joined:</div>

                    <div class="ml-1 text-sm text-gray-500">
                        {{
                            format(
                                new Date(selectedUser.email_verified_at),
                                "d. L. yyyy '('HH:mm:ss')'"
                            )
                        }}
                    </div>
                </div>

                <div class="mt-1 flex">
                    <div class="text-sm font-medium text-gray-800">
                        Last login:
                    </div>

                    <div
                        v-if="selectedUser.last_login_at"
                        class="ml-1 text-sm text-gray-500"
                    >
                        {{
                            format(
                                new Date(selectedUser.last_login_at),
                                "d. L. yyyy '('HH:mm:ss')'"
                            )
                        }}
                    </div>
                </div>

                <div class="mt-1 flex items-center">
                    <div class="text-sm font-medium text-gray-800">
                        Two-factor authentication:
                    </div>

                    <div
                        v-if="selectedUser.two_factor_enabled"
                        class="ml-1 flex items-center text-sm font-medium text-green-600"
                    >
                        Enabled
                        <span
                            class="ml-1 cursor-pointer text-xs font-medium text-custom-red-600 underline hover:opacity-90"
                            @click="
                                handleDisableTwoFactorAuthentication(
                                    selectedUser
                                )
                            "
                            >(disable)</span
                        >
                    </div>
                    <div
                        v-else
                        class="ml-1 text-sm font-medium text-custom-red-600"
                    >
                        Disabled
                    </div>
                </div>

                <div class="mt-4">
                    <div class="text-sm font-medium text-gray-800">Note:</div>
                    <div class="mt-1 max-w-xs text-base text-gray-500">
                        {{ selectedUser.admin_note }}
                    </div>
                    <div
                        class="mt-1 cursor-pointer text-xs font-medium text-blue-500 underline hover:opacity-90"
                        @click="editNote(selectedUser)"
                    >
                        add/edit note
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { format } from "date-fns";
import { TrashIcon, EyeIcon, AtSymbolIcon } from "@heroicons/vue/24/outline";

import route from "ziggy-js";
import { router } from "@inertiajs/vue3";
import IUser from "@/types/Members/IUser";
import useConfirm from "@/composables/useConfirm";
import { create } from "@/composables/useNotifications";
import axios from "axios";
import ICurrentTeam from "@/types/Members/ICurrentTeam";

defineProps<{
    selectedUser: IUser;
    personalTeam: ICurrentTeam;
}>();

const { show } = useConfirm();

const handleDeleteUser = async (selectedUser: IUser) => {
    const confirmed = await show({
        header: `Delete user ${selectedUser.name}`,
        message: `Are you sure you want to delete user ${selectedUser.name}?`,
        type: "danger",
        confirm: "Delete user",
    });
    if (confirmed) {
        await deleteUser(selectedUser);
    }
};

const handleResetPassword = async (selectedUser: IUser) => {
    const confirmed = await show({
        header: `Reset password for user ${selectedUser.name}`,
        message: `Are you sure you want to sent reset password link for user ${selectedUser.name}?`,
        type: "danger",
        confirm: "Reset password",
    });
    if (confirmed) {
        await resetPassword(selectedUser);
    }
};

const handleImpersonate = (selectedUser: IUser) => {
    router.get(route("kiosk.users.impersonate", { user: selectedUser.id }));
};

const deleteUser = async (selectedUser: IUser) => {
    try {
        const res = await axios.delete(
            route("kiosk.users.delete", { user: selectedUser.id })
        );
        if (res.data.success) {
            create({
                title: `User deleted`,
                type: "success",
            });
            router.get(route("kiosk.users.index"));
        } else {
            console.log(res.data);

            create({
                title: "User not deleted",
                text: `There was an error deleting user.`,
                type: "error",
            });
        }
    } catch (err) {
        console.log(err);

        create({
            title: "User not deleted",
            text: `There was an error deleting user.`,
            type: "error",
        });
    }
};

const resetPassword = async (selectedUser: IUser) => {
    try {
        const res = await axios.post(
            route("kiosk.users.reset-password", { user: selectedUser.id })
        );
        if (res.data.success) {
            create({
                title: `Reset link has been sent`,
                type: "success",
            });
        } else {
            create({
                title: "Reset link has not been sent",
                text: `There was an error sending reset password link user.`,
                type: "error",
            });
        }
    } catch (err) {
        create({
            title: "Reset link has not been sent",
            text: `There was an error sending reset password link user.`,
            type: "error",
        });
    }
};

const handleDisableTwoFactorAuthentication = async (selectedUser: IUser) => {
    const confirmed = await show({
        header: `Disable 2FA for user ${selectedUser.name}`,
        message: `Are you sure you want to disable two-factor authentication for user ${selectedUser.name}?`,
        type: "danger",
        confirm: "Disable",
    });
    if (confirmed) {
        await disableTwoFactorAuthentication(selectedUser);
    }
};

const disableTwoFactorAuthentication = (selectedUser: IUser) => {
    router.put(
        route("kiosk.users.2fa.disable", { user: selectedUser.id }),
        {},
        {
            preserveScroll: true,
            onSuccess: () => {
                create({
                    title: `2FA for ${selectedUser.name} disabled`,
                    type: "success",
                });
            },
            onError: () => {
                create({
                    title: `2FA for ${selectedUser.name} not disabled`,
                    text: `There was an error disabling 2FA.`,
                    type: "error",
                });
            },
        }
    );
};

const editNote = async (selectedUser: IUser) => {
    const note = await show({
        header: "Add / edit note",
        hasInput: true,
        confirm: "Update",
        inputData: {
            label: "Enter note",
        },
    });

    if (note) {
        router.put(
            route("kiosk.users.update", { user: selectedUser.id }),
            {
                note: note,
            },
            {
                preserveScroll: true,
            }
        );
    }
};
</script>
