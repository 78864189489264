<template>
    <tr>
        <TableRowItem
            v-for="(header, key) in props.headers"
            :key="key"
            :item-key="header.id"
            :item="item"
        >
            <!-- slot proxy to child component -->
            <template v-for="(_, name) in $slots" #[name]="slotData">
                <slot :name="name" v-bind="slotData" />
            </template>
        </TableRowItem>
    </tr>
</template>

<script lang="ts" setup>
import IDataTableHeader from "../types/IDataTableHeader";
import { IDataTableItem } from "../types/IDataTableItem";
import TableRowItem from "./TableRowItem.vue";

const props = defineProps<{
    item: IDataTableItem;
    headers: IDataTableHeader[];
}>();
</script>
