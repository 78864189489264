<template>
    <div class="py-12">
        <inertia-link :href="route('kiosk.announcements.index')">
            <AtomicButton color="yellow" size="sm">
                Back to Announcements
                <template #icon>
                    <BackspaceIcon
                        class="mr-2 h-6 w-6 transition duration-200 group-hover:text-gray-600"
                    />
                </template>
            </AtomicButton>
        </inertia-link>

        <inertia-link
            :href="
                route('kiosk.announcements.edit', {
                    announcement: announcement.id,
                })
            "
        >
            <AtomicButton class="mx-2 mt-4" size="sm" color="white">
                Edit
                <template #icon>
                    <PencilSquareIcon
                        class="mr-2 h-6 w-6 transition duration-200 group-hover:text-gray-600"
                    />
                </template>
            </AtomicButton>
        </inertia-link>

        <AtomicButton
            size="sm"
            color="red"
            @click.prevent="deleteAnnouncement(announcement)"
        >
            Delete
            <template #icon>
                <TrashIcon
                    class="mr-2 h-6 w-6 transition duration-200 group-hover:text-gray-600"
                />
            </template>
        </AtomicButton>

        <h1 class="mt-4 text-2xl font-medium text-gray-800">
            {{ announcement.title }}
        </h1>

        <div
            class="mt-4 inline-flex rounded-md px-2 py-[3px] text-xs font-medium text-gray-700"
            :class="{
                'bg-indigo-200': announcement.type === 'news',
                'bg-gray-200': announcement.type === 'report',
            }"
        >
            {{ announcement.type }}
        </div>

        <div class="mt-4 flex text-gray-800">
            <h3 class="mr-2">Published:</h3>
            <div v-if="announcement.published_at">
                {{
                    format(
                        new Date(announcement.published_at),
                        "EEEE, d. L. yyyy 'at' H:m"
                    )
                }}
            </div>
            <XCircleIcon v-else class="h-6 w-6 text-gray-600" />
        </div>

        <div></div>

        <div
            class="mt-4 text-base text-gray-800"
            v-html="announcement.data"
        ></div>

        <a v-if="announcement.button_text" :href="announcement.button_url">
            <AtomicButton size="lg" color="purple" class="mt-2">
                {{ announcement.button_text }}
            </AtomicButton>
        </a>
    </div>
</template>

<script lang="ts" setup>
import { format } from "date-fns";
import route from "ziggy-js";
import {
    PencilSquareIcon,
    TrashIcon,
    BackspaceIcon,
    XCircleIcon,
} from "@heroicons/vue/24/outline";

import { PropType } from "vue";

import IAnnouncement from "@/types/Announcements/IAnnouncement";
import useConfirm from "@/composables/useConfirm";
import { router } from "@inertiajs/vue3";
import { create } from "@/composables/useNotifications";

defineProps({
    announcement: {
        type: Object as PropType<IAnnouncement>,
        required: true,
    },
});

const { show } = useConfirm();

const deleteAnnouncement = async (announcement: IAnnouncement) => {
    const confirmed = await show({
        header: `Delete announcement`,
        message: `Are you sure you want to delete this announcement?`,
        type: "danger",
        confirm: "Delete",
    });

    if (confirmed) {
        router.delete(
            route("kiosk.announcements.destroy", {
                announcement: announcement.id,
            }),
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: "Announcement deleted",
                        type: "success",
                    });
                },
                onError: () => {
                    create({
                        title: "Announcement not deleted",
                        type: "error",
                    });
                },
            }
        );
    }
};
</script>

<style></style>
