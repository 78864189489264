<template>
    <AuthLayout>
        <div
            class="mt-8 w-full overflow-hidden bg-white p-6 shadow-xl sm:mt-14 sm:max-w-md sm:rounded-lg"
        >
            <div class="mb-8 mt-[2px] border-b border-gray-200 pb-5">
                <h1 class="text-center text-xl font-bold text-gray-800">
                    Create new account
                </h1>
            </div>

            <form @submit.prevent="submit">
                <div>
                    <TextInput
                        v-model="state.form.email"
                        type="email"
                        autocomplete="email"
                        name="email"
                        required
                        suppress-required-icon
                        autofocus
                        label="Email address"
                        placeholder="Enter your email address"
                        :error="
                            getError('email') || getError('recaptcha_token')
                        "
                        @blur="v$.form.email.$touch()"
                    />
                </div>

                <div class="mt-6">
                    <TextInput
                        v-model="v$.form.name.$model"
                        type="text"
                        autocomplete="name"
                        name="name"
                        required
                        suppress-required-icon
                        label="Your name"
                        placeholder="Enter your name"
                        :error="getError('name')"
                    />
                </div>

                <div class="mt-6">
                    <SelectInput
                        v-model="state.form.country"
                        placeholder="Choose your country of residence"
                        :data="countries"
                        label="Country"
                        required
                        suppress-required-icon
                        data-testid="country"
                        :error="getError('country')"
                        :disabled="countriesLoading"
                        search
                        @blurred="v$.form.country.$touch()"
                    >
                    </SelectInput>
                </div>

                <div class="mt-6">
                    <TextInput
                        v-model="state.form.password"
                        type="password"
                        autocomplete="new-password"
                        name="password"
                        required
                        suppress-required-icon
                        label="Set password"
                        placeholder="Set your password"
                        :error="getError('password')"
                        @blur="v$.form.password.$touch()"
                    />
                </div>

                <div class="mt-6">
                    <TextInput
                        v-model="state.form.password_confirmation"
                        type="password"
                        autocomplete="new-password"
                        name="password_confirmation"
                        required
                        suppress-required-icon
                        label="Confirm password"
                        placeholder="Your password again, please"
                        :error="getError('password_confirmation')"
                        @blur="v$.form.password_confirmation.$touch()"
                    />
                </div>

                <div class="mt-10 flex flex-col items-center pb-3">
                    <MegaButton :disabled="loading" type="submit"
                        >Create account
                    </MegaButton>
                </div>
            </form>
        </div>
        <div class="mt-11 text-center text-sm text-gray-500">
            Already have an account?

            <inertia-link :href="route('login')">
                <span
                    class="font-medium text-custom-blue-500 hover:text-custom-blue-600"
                    >Sign in!</span
                >
            </inertia-link>
        </div>
    </AuthLayout>
</template>

<script lang="ts" setup>
import route from "ziggy-js";
import { useReCaptcha } from "vue-recaptcha-v3";
import { computed, reactive, ref } from "vue";
import {
    required,
    email as emailRule,
    maxLength,
    minLength,
    helpers,
    sameAs,
} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { router } from "@inertiajs/vue3";

const props = defineProps({
    email: {
        required: false,
        type: String,
        default: "",
    },
    invitationToken: {
        required: false,
        type: String,
        default: "",
    },
});

const reCaptcha = useReCaptcha();

interface IForm {
    form: {
        name: string;
        email: string;
        password: string;
        password_confirmation: string;
        terms: boolean;
        country: string;
        recaptcha_token: string;
        invitation_token: string;
    };
}

const state: IForm = reactive({
    form: {
        name: "",
        email: props.email,
        password: "",
        password_confirmation: "",
        terms: true,
        country: "",
        recaptcha_token: "",
        invitation_token: props.invitationToken,
    },
});

const passwordRef = computed(() => state.form.password);

const rules = {
    form: {
        name: {
            required,
            maxLength: maxLength(255),
        },
        email: {
            required,
            emailRule,
            maxLength: maxLength(255),
        },
        password: {
            required,
            minLength: minLength(8),
            maxLength: maxLength(255),
        },
        password_confirmation: {
            required,
            minLength: minLength(8),
            maxLength: maxLength(255),
            sameAsPassword: helpers.withMessage(
                "Passwords do not match",
                sameAs(passwordRef)
            ),
        },
        country: {
            required,
        },
    },
};

const countries = ref([]);
const v$ = useVuelidate(rules, state);
const errors = ref({});
const loading = ref(false);
const countriesLoading = ref(false);

onMounted(() => {
    getCountries();
});

const getCountries = async () => {
    countriesLoading.value = true;
    try {
        const res = await axios.get(route("countries.index"));
        countries.value = res.data.data;
    } catch (e) {
        console.error(e);
    } finally {
        countriesLoading.value = false;
    }
};

const submit = async () => {
    v$.value.form.$touch();
    if (v$.value.form.$error) {
        () => undefined;
    } else {
        loading.value = true;
        let token = undefined;

        try {
            await reCaptcha?.recaptchaLoaded();
            token = await reCaptcha?.executeRecaptcha("register");

            if (token === undefined) {
                throw new Error();
            }
        } catch (e) {
            loading.value = false;
            errors.value = {
                recaptcha_token: "ReCaptcha validation failed.",
            };
            return;
        }
        state.form.recaptcha_token = token;

        router.post(route("register"), state.form, {
            preserveScroll: true,
            onError: (err) => {
                errors.value = err;
            },
            onFinish: () => {
                loading.value = false;
                state.form.password = "";
                state.form.password_confirmation = "";
                v$.value.$reset();
            },
        });
    }
};

const getError = (field: string) => {
    // @ts-ignore
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ""
    );
};
</script>
