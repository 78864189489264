<template>
    <div class="mt-10 rounded-md bg-white p-6 shadow-md">
        <div
            class="flex flex-col gap-4 sm:flex-row sm:items-center sm:justify-between"
        >
            <div class="flex flex-col sm:flex-row sm:items-center">
                <div
                    class="h-20 w-20 shrink-0 cursor-pointer rounded-full ring-[1px] ring-gray-300"
                >
                    <img
                        :src="selectedTeam.profile_photo_url"
                        class="h-20 w-20 rounded-full bg-white object-cover"
                    />
                </div>
                <div class="mt-2 flex flex-col sm:ml-10 sm:mt-0">
                    <h2 class="text-2xl font-bold text-gray-800">
                        {{ selectedTeam.name }}
                    </h2>
                    <div class="text-xl font-medium text-gray-500">
                        {{ selectedTeam.email }}
                    </div>
                </div>
            </div>

            <div>
                <AtomicButton
                    size="lg"
                    color="red"
                    @click="handleDeleteTeam(selectedTeam)"
                >
                    <template #icon>
                        <TrashIcon class="mr-2 h-6 w-6" />
                    </template>
                    Delete team
                </AtomicButton>
            </div>
        </div>
        <div class="mt-5 grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
            <div class="flex flex-col">
                <div class="flex">
                    <div class="text-sm font-medium text-gray-800">ID:</div>

                    <div class="ml-1 text-sm text-gray-500">
                        {{ selectedTeam.id }}
                    </div>
                </div>

                <div class="mt-1 flex">
                    <div class="text-sm font-medium text-gray-800">
                        Created:
                    </div>

                    <div class="ml-1 text-sm text-gray-500">
                        {{
                            format(
                                new Date(selectedTeam.created_at),
                                "d. L. yyyy '('HH:mm:ss')'"
                            )
                        }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { format } from "date-fns";
import { TrashIcon } from "@heroicons/vue/24/outline";

import route from "ziggy-js";
import { router } from "@inertiajs/vue3";
import useConfirm from "@/composables/useConfirm";
import { create } from "@/composables/useNotifications";
import ICurrentTeam from "@/types/Members/ICurrentTeam";
import axios from "axios";

defineProps<{
    selectedTeam: ICurrentTeam;
}>();

const { show } = useConfirm();

const handleDeleteTeam = async (selectedTeam: ICurrentTeam) => {
    const confirmed = await show({
        header: `Delete team ${selectedTeam.name}`,
        message: `Are you sure you want to delete team ${selectedTeam.name}?`,
        type: "danger",
        confirm: "Delete team",
    });
    if (confirmed) {
        await deleteTeam(selectedTeam);
    }
};

const deleteTeam = async (selectedTeam: ICurrentTeam) => {
    try {
        const res = await axios.delete(
            route("kiosk.teams.delete", { team: selectedTeam.id })
        );
        if (res.data.success) {
            create({
                title: `Team deleted`,
                type: "success",
            });
            router.get(route("kiosk.teams.index"));
        } else {
            create({
                title: "Team not deleted",
                text: `There was an error deleting team.`,
                type: "error",
            });
        }
    } catch (err) {
        create({
            title: "Team not deleted",
            text: `There was an error deleting team.`,
            type: "error",
        });
    }
};
</script>
