<template>
    <div class="relative bg-gray-100">
        <Navbar @handleSideBarMobile="() => {}" />

        <div
            v-if="globalProps.user.isImpersonating"
            class="fixed bottom-0 left-0 z-[500] flex w-full flex-wrap items-center justify-between bg-gray-900 bg-opacity-50 px-10 py-2"
        >
            <div class="my-1 text-sm text-white">
                You are now seeing the app as: {{ globalProps.user.name }}
            </div>
            <AtomicButton size="sm" color="purple" @click="endImpersonation">
                <template #icon>
                    <EyeSlashIcon class="mr-2 h-6 w-6" />
                </template>
                Leave impersonation
            </AtomicButton>
        </div>

        <div
            class="grid min-h-screen grid-cols-1 md:grid-cols-[64px_1fr] xl:grid-cols-[256px_1fr]"
        >
            <Sidebar
                v-click-away="onClickAway"
                :opened="sidebarOpened"
                @close="sidebarOpened = false"
                @open="sidebarOpened = true"
            />
            <div
                class="mx-auto w-full px-5 pt-[56px] opacity-100 blur-none transition duration-300 ease-in-out sm:px-10 md:pt-0 xl:max-w-[1328px] xl:opacity-100 xl:blur-none"
                :class="{
                    'pointer-events-none md:opacity-90 md:blur-sm':
                        sidebarOpened,
                }"
            >
                <slot />
            </div>
        </div>

        <ConfirmDialog></ConfirmDialog>
        <Notifications></Notifications>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from "vue";
import { usePage } from "@inertiajs/vue3";
import { IMailClockerPageProps } from "@/types/MailClockerPageProps/MailClockerPageProps";
import { router } from "@inertiajs/vue3";
import route from "ziggy-js";
import { EyeSlashIcon } from "@heroicons/vue/24/outline";
import Bugsnag from "@bugsnag/js";
import ICurrentTeam from "@/types/Members/ICurrentTeam";

const globalProps = computed(() => usePage<IMailClockerPageProps>().props);
const teamProps = computed(() => globalProps.value.team as ICurrentTeam);

onMounted(() => {
    const userObject = {
        id: globalProps.value.user.id,
        email: globalProps.value.user.email,
        current_team_id: teamProps.value.id,
    };
    Bugsnag.setUser(JSON.stringify(userObject));

    getData();
});

const { fetchTeams, getCurrentTeamMembersCount } = useTeamsAndMembers();

const getData = async () => {
    await getCurrentTeamMembersCount();
    await fetchTeams();
};

const sidebarOpened = ref(false);
const onClickAway = () => {
    sidebarOpened.value = false;
};
const endImpersonation = () => {
    router.delete(route("kiosk.users.nonimpersonate"), {
        onSuccess: () => {
            getData();
        },
    });
};
</script>

<style></style>
