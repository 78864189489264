<template>
    <BasicTabs :tabs="tabs" :tab-key="tabKey" title="Settings">
        <slot />
    </BasicTabs>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { usePage } from "@inertiajs/vue3";
import { UserIcon, UserGroupIcon, KeyIcon } from "@heroicons/vue/24/solid";
import route from "ziggy-js";
import TabItem from "@/types/Settings/TabItem";
import { IMailClockerPageProps } from "@/types/MailClockerPageProps/MailClockerPageProps";

defineProps({
    tabKey: {
        type: String,
        default: "profile",
    },
});

const isPersonalTeam = computed(
    () => usePage<IMailClockerPageProps>().props.team.personal_team
);

const tabs: TabItem[] = isPersonalTeam.value
    ? [
          {
              title: "Profile",
              key: "profile-settings",
              icon: UserIcon,
              url: route("settings.profile-settings.index"),
          },
          {
              title: "API Tokens",
              key: "api-tokens",
              icon: KeyIcon,
              url: route("settings.api-tokens.index"),
          },
      ]
    : [
          {
              title: "Team settings",
              key: "team-settings",
              icon: UserGroupIcon,
              url: route("settings.team-settings.index"),
          },
      ];
</script>
