<template>
    <AuthLayout>
        <div
            class="mt-8 w-full overflow-hidden bg-white p-6 shadow-xl sm:mt-14 sm:max-w-md sm:rounded-lg"
        >
            <div class="mb-8 mt-[2px] border-b border-gray-200 pb-4">
                <h1 class="text-center text-xl font-bold text-gray-800">
                    Reset your password
                </h1>

                <div
                    class="mx-auto mt-3 max-w-[350px] text-center text-sm text-gray-500"
                >
                    Enter your email and we will send you instructions how to
                    reset your password.
                </div>
            </div>

            <form @submit.prevent="submit">
                <div>
                    <TextInput
                        id="email"
                        v-model="state.form.email"
                        type="email"
                        required
                        suppress-required-icon
                        autofocus
                        label="Email address"
                        placeholder="Enter your email address"
                        name="email"
                        autocomplete="email"
                        :error="
                            getError('email') || getError('recaptcha_token')
                        "
                        @blur="v$.form.email.$touch()"
                    />
                </div>

                <div class="mt-10 flex flex-col items-center">
                    <MegaButton
                        id="login-button"
                        :disabled="loading"
                        type="submit"
                        >Send password reset link
                    </MegaButton>

                    <inertia-link
                        :href="route('login')"
                        class="mt-6 pb-2 text-xs font-medium text-custom-blue-500 hover:text-custom-blue-600"
                        >Cancel</inertia-link
                    >
                </div>
            </form>
        </div>
    </AuthLayout>
</template>

<script lang="ts" setup>
import { reactive, ref } from "vue";
import route from "ziggy-js";
import { required, email, maxLength } from "@vuelidate/validators";
import { useReCaptcha } from "vue-recaptcha-v3";
import useVuelidate from "@vuelidate/core";
import { router } from "@inertiajs/vue3";
import IInputErrors from "@/types/Atomic/IInputErrors";

const reCaptcha = useReCaptcha();

const state = reactive({
    form: {
        email: "",
        recaptcha_token: "",
    },
});

const rules = {
    form: {
        email: { required, email, maxLength: maxLength(255) },
    },
};

const loading = ref(false);
const errors = ref<IInputErrors>({});
const v$ = useVuelidate(rules, state);

const submit = async () => {
    v$.value.form.$touch();
    if (v$.value.form.$error) {
        () => undefined;
    } else {
        loading.value = true;
        let token = undefined;

        try {
            await reCaptcha?.recaptchaLoaded();
            token = await reCaptcha?.executeRecaptcha("forgotPassword");

            if (token === undefined) {
                throw new Error();
            }
        } catch (e) {
            loading.value = false;
            errors.value = {
                recaptcha_token: "ReCaptcha validation failed.",
            };
            return;
        }
        state.form.recaptcha_token = token;
        router.post(route("password.email"), state.form, {
            preserveScroll: true,
            onError: (err) => {
                errors.value = err;
            },
            onFinish: () => {
                loading.value = false;
            },
        });
    }
};

const getError = (field: string) => {
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ""
    );
};
</script>
