import { ref, UnwrapRef, computed } from "vue";
import { useDebounceFn } from "@vueuse/core";
import axios from "axios";
import IApiResponse from "@/types/IApiResponse";

export const useResource = <T>(
    resourceUrl: string,
    config = { perPage: 30, hasInfiniteScroll: false }
) => {
    const data = ref<T[]>([]);
    const searchQuery = ref("");
    const currentPage = ref(1);
    const per_page = ref(config.perPage);
    const hasInfiniteScroll = ref(config.hasInfiniteScroll);

    const canFetchMore = ref(true);

    const sortBy = ref("");
    const sortDirection = ref("asc"); //asc || desc

    const query = computed(() => {
        let q = resourceUrl;

        q += `?current_page=${currentPage.value}`;

        q += `&per_page=${per_page.value}`;

        if (searchQuery.value !== "") {
            q += `&search=${searchQuery.value}`;
        }
        if (sortBy.value !== "") {
            q += `&sort_by=${sortBy.value}`;
            q += `&sort_by_direction=${sortDirection.value}`;
        }

        return q;
    });

    const handleQueryChange = async () => {
        await fetchData();
    };

    const fetchData = async () => {
        try {
            const res = await axios.get<IApiResponse<UnwrapRef<T[]>>>(
                query.value
            );

            //@ts-ignore
            if (res.data.current_page >= res.data.last_page) {
                canFetchMore.value = false;
            }

            if (hasInfiniteScroll.value) {
                data.value.push(...res.data.data);
            } else {
                data.value = res.data.data;
            }
        } catch (err) {
            throw new Error("Data was unabled to fetch from given resource!");
        }
    };

    const search = async (searchQ: string, debounceTime = 220) => {
        useDebounceFn(async () => {
            searchQuery.value = searchQ;
            await handleQueryChange();
        }, debounceTime);
    };

    const setSortDirection = async (sortDir: "asc" | "desc") => {
        if (sortDir === "asc" || sortDir === "desc") {
            sortDirection.value = sortDir;
            await handleQueryChange();
        } else {
            throw new TypeError(
                `Sorting direction: ${sortDir} is not supported. Supported directions: 'asc' or 'desc'.`
            );
        }
    };

    const setSortBy = async (sortByKey: string) => {
        //possible validation for sortByKey
        sortBy.value = sortByKey;
        await handleQueryChange();
    };

    const setCurrentPage = async (newPage: number) => {
        currentPage.value = newPage;
        await handleQueryChange();
    };

    return {
        data,
        fetchData,
        search,
        setSortDirection,
        setSortBy,
        setCurrentPage,
        currentPage,
        canFetchMore,
    };
};
