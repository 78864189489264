import { AxiosError } from "axios";
import { router } from "@inertiajs/vue3";
import route from "ziggy-js";

export const getMessageForError = (axiosError: AxiosError): string => {
    const errorCode = axiosError.response?.status;

    if (errorCode === 401) {
        router.get(route("login"));
    }

    const errors = [
        {
            code: 503,
            errorMessage:
                "Sorry, we are doing some maintenance. Please check back soon.",
        },
        {
            code: 500,
            errorMessage: "Looks like server failed to load your request.",
        },
        {
            code: 405,
            errorMessage: "We're sorry, but this method is not allowed.",
        },
        {
            code: 404,
            errorMessage: "We can't seem to find the page you are looking for.",
        },
        {
            code: 403,
            errorMessage:
                "We're sorry, but you don't have access to this page or resource.",
        },
        {
            code: 401,
            errorMessage: "We're sorry, but you are not authenticated.",
        },
    ];

    const foundError = errors.find((e) => e.code === errorCode);

    if (foundError) {
        return foundError.errorMessage;
    }

    return "An error has ocurred.";
};
