import { IMailClockerPageProps } from "@/types/MailClockerPageProps/MailClockerPageProps";
import { usePage } from "@inertiajs/vue3";
import { computed } from "vue";
import { FunctionalComponent } from "vue";
import { MaybeRef } from "@vueuse/core";

import {
    UserGroupIcon,
    Cog8ToothIcon,
    UserIcon,
    UsersIcon,
    SpeakerWaveIcon,
    IdentificationIcon,
    ClockIcon,
} from "@heroicons/vue/24/outline";

interface ILink {
    name: string;
    href: string;
    icon: FunctionalComponent;
    count?: MaybeRef<number>;
    id: string;
    tooltip?: string;
}

const { isOwner, isAdmin } = usePermissions();
const { membersCount } = useTeamsAndMembers();

export default () => {
    const page = computed(() => usePage<IMailClockerPageProps>().url);
    const isPersonalTeam = computed(
        () => usePage<IMailClockerPageProps>().props.team.personal_team
    );

    const linksApp = computed((): ILink[] => {
        const links: ILink[] = [
            {
                name: "Countdowns",
                href: route("countdowns.index"),
                icon: ClockIcon,
                id: "/countdowns",
            },
        ];
        if (!isPersonalTeam.value) {
            links.splice(2, 0, {
                name: "Members",
                href: route("members.index"),
                icon: UserGroupIcon,
                count: membersCount.value,
                id: "/members",
            });
        }
        if (isOwner.value || isAdmin.value) {
            links.splice(5, 0, {
                name:
                    isPersonalTeam.value === true
                        ? "Settings"
                        : "Team Settings",
                href:
                    isPersonalTeam.value === true
                        ? route("settings.profile-settings.index")
                        : route("settings.team-settings.index"),
                icon: Cog8ToothIcon,
                count: undefined,
                id: "/settings",
            });
        }
        return links;
    });

    const linksKiosk = computed((): ILink[] => {
        return [
            {
                name: "Users",
                href: route("kiosk.users.index"),
                icon: UserIcon,
                id: "/kiosk/users",
            },
            {
                name: "Teams",
                href: route("kiosk.teams.index"),
                icon: UsersIcon,
                id: "/kiosk/teams",
            },
            {
                name: "Admins",
                href: route("kiosk.admins.index"),
                icon: IdentificationIcon,
                id: "/kiosk/admins",
            },
            {
                name: "Announcements",
                href: route("kiosk.announcements.index"),
                icon: SpeakerWaveIcon,
                id: "/kiosk/announcements",
            },
            // {
            //     name: 'Lambdas',
            //     href: route('kiosk.lambdas.index'),
            //     icon: MusicalNoteIcon,
            //     id: '/kiosk/lambdas',
            // },
        ];
    });
    const links = computed(() => {
        return page.value.startsWith("/kiosk")
            ? linksKiosk.value
            : linksApp.value;
    });

    return {
        links,
    };
};
