import { reactive, onMounted } from "vue";
import axios from "axios";
import IApiResponse from "../../../../types/IApiResponse";
import route from "ziggy-js";

export interface PairingCodes {
    key?: string;
    qrCode?: string;
}

const data = reactive<PairingCodes>({
    key: undefined,
    qrCode: undefined,
});

export default (routeName: string) => {
    const getPairingCodes = async () => {
        const response = await axios.post<IApiResponse<PairingCodes>>(
            route(routeName)
        );
        data.key = response.data.data.key;
        data.qrCode = response.data.data.qrCode;
    };

    onMounted(async () => {
        await getPairingCodes();
    });

    return {
        paringCodes: data,
    };
};
