<template>
    <UserTabs
        :selected-user="selectedUser"
        :personal-team="userPersonalTeam"
        tab-key="feature-management"
    >
        <div class="mt-12 rounded-md bg-white p-6 text-gray-900 shadow-md">
            <div class="grid grid-cols-4">
                <div>
                    <div>
                        <div class="text-base font-medium text-gray-700">
                            {{ personalTeam.name }}
                        </div>

                        <div
                            v-for="(newFeature, index) in unusedNewFeatures(
                                newFeatures,
                                featuresArray(personalTeam.features)
                            )"
                            :key="index"
                            class="my-2 flex items-center"
                        >
                            <div class="mr-4 text-sm text-gray-600">
                                {{ newFeature.value }}:
                            </div>

                            <CheckboxInput
                                v-model:checked="newFeature.checked"
                                size="md"
                                :name="newFeature.value"
                                @change="
                                    updateFeature(newFeature, personalTeam)
                                "
                            />
                        </div>

                        <div v-if="personalTeam.features">
                            <div
                                v-for="(feature, index) in featuresArray(
                                    personalTeam.features
                                )"
                                :key="index"
                                class="my-2 flex items-center"
                            >
                                <div class="mr-4 text-sm text-gray-600">
                                    {{ feature.value }}:
                                </div>

                                <CheckboxInput
                                    v-model:checked="feature.checked"
                                    size="md"
                                    :name="feature.value"
                                    @change="
                                        updateFeature(feature, personalTeam)
                                    "
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </UserTabs>
</template>
<script lang="ts" setup>
import IUser from "@/types/Members/IUser";
import { router } from "@inertiajs/vue3";
import route from "ziggy-js";
import ICurrentTeam from "@/types/Members/ICurrentTeam";

interface IFeature {
    value: string;
    checked: boolean;
}

defineProps<{
    selectedUser: IUser;
    personalTeam: ICurrentTeam;
    newFeatures: IFeature[];
    userPersonalTeam: ICurrentTeam;
}>();

const updateFeature = (feature: IFeature, team: ICurrentTeam) => {
    router.put(
        route("kiosk.users.feature-management.update", {
            team: team.id,
            feature: feature.value,
            checked: feature.checked,
        }),
        {},
        {
            preserveScroll: true,
        }
    );
};

const unusedNewFeatures = (newFeatures: IFeature[], features: IFeature[]) => {
    return newFeatures.filter(
        (newFeature) =>
            !features.some((feature) => feature.value === newFeature.value)
    );
};

const featuresArray = (featureArr: object | undefined | null) => {
    const features = [] as IFeature[];
    if (featureArr === undefined || featureArr === null) {
        return features;
    }

    Object.entries(featureArr).forEach(([key, value]) =>
        features.push({ value: key, checked: value })
    );
    return features;
};
</script>
