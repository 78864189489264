<template>
    <div class="py-12">
        <DataTable title="Teams" searchable :headers="headers" :items="teams">
            <template #item-profile_photo_url="{ data }">
                <div class="flex justify-center">
                    <img
                        v-if="data"
                        class="h-10 w-10 rounded-full"
                        :src="(data as string)"
                    />
                    <img
                        v-else
                        class="h-10 w-10 rounded-full"
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/600px-No_image_available.svg.png"
                    />
                </div>
            </template>

            <template #item-custom="{ row }">
                <div class="flex w-full justify-center">
                    <AtomicButton
                        color="white"
                        size="sm"
                        @click="handleShowTeam(row)"
                    >
                        <template #icon>
                            <MagnifyingGlassIcon class="h-6 w-6" />
                        </template>
                    </AtomicButton>
                </div>
            </template>
        </DataTable>
    </div>
</template>

<script lang="ts" setup>
import IPaginationData from "@/types/Atomic/IPaginationData";
import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline";
import route from "ziggy-js";
import IDataTableHeader from "@/components/Atomic/DataTable/types/IDataTableHeader";
import { router } from "@inertiajs/vue3";
import ICurrentTeam from "@/types/Members/ICurrentTeam";

defineProps<{
    teams: IPaginationData<ICurrentTeam>;
}>();

const headers: IDataTableHeader[] = [
    { text: "", id: "profile_photo_url" },
    {
        text: "Name",
        id: "name",
        sortable: true,
        sortableId: "name",
    },
    {
        text: "Owner Email",
        id: "email",
        sortable: true,
        sortableId: "email",
    },
    { text: "", id: "custom" },
];

const handleShowTeam = function (team: ICurrentTeam) {
    router.get(
        route("kiosk.teams.members.index", {
            team: team.id,
        })
    );
};
</script>

<style></style>
