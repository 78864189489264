<template>
    <div class="relative flex items-start">
        <div class="flex h-5 items-center">
            <input
                :id="randomId"
                :checked="checked"
                :name="name"
                :value="name"
                :disabled="disabled"
                role="checkbox"
                type="checkbox"
                :class="[
                    'form-checkbox cursor-pointer rounded border shadow-button transition duration-150 ease-in-out focus:ring-2 focus:ring-offset-2 disabled:cursor-not-allowed disabled:border-gray-300 disabled:text-white disabled:shadow-none disabled:hover:border-gray-300',
                    {
                        'h-6 w-6 checked:bg-[length:10px_8px]': size === 'lg',
                        'h-4 w-4 checked:bg-[length:8px_6px]': size === 'md',
                        'checked-tick': checked && !disabled && !hasError,
                        'disabled-tick': disabled,
                        'error-tick border-custom-alert-100 bg-red-200 text-red-200 checked:border checked:border-custom-alert-100 checked:hover:border-custom-alert-100 checked:focus:border-custom-alert-100 disabled:border-gray-300 disabled:text-white':
                            hasError && !disabled,
                        [textColor]: !hasError,
                    },
                    ringColor,
                ]"
                @change="handleInput"
            />
        </div>

        <label
            class="cursor-pointer select-none pl-2 text-left text-sm leading-5"
            :class="{
                'text-gray-700': !hasError && !disabled,
                'text-custom-alert-100': hasError && !disabled,
                'text-gray-400': disabled,
            }"
            :for="randomId"
            v-html="label"
        >
        </label>
    </div>
</template>

<script lang="ts" setup>
const props = defineProps({
    checked: Boolean,
    label: {
        type: String,
        default: "",
    },
    name: {
        type: String,
        default: "",
    },
    disabled: Boolean,
    hasError: Boolean,
    size: {
        type: String,
        validator(value: string) {
            return ["md", "lg"].includes(value);
        },
        default: "md",
    },
    color: {
        type: String,
        validator(value: string) {
            return ["orange", "blue", "purple"].includes(value);
        },
        default: "orange",
    },
});

const textColor = computed(() => {
    if (!props.hasError) {
        let textColor = "";
        switch (props.color) {
            case "orange":
                textColor = "text-orange-500";
                break;
            case "purple":
                textColor = "text-purple-300";
                break;
            case "blue":
                textColor = "text-blue-200";
                break;
        }
        return "border-gray-300" + " " + textColor;
    }
    return "";
});

const ringColor = computed(() => {
    let ringColor = "";
    switch (props.color) {
        case "orange":
            ringColor = "focus:ring-orange-500";
            break;
        case "purple":
            ringColor = "focus:ring-purple-300";
            break;
        case "blue":
            ringColor = "focus:ring-blue-200";
            break;
    }
    return ringColor;
});

const emit = defineEmits(["update:checked"]);

const randomId = props.name + Math.floor(Math.random() * 100).toString();

const handleInput = (event: Event) => {
    if (props.disabled) return;
    const ischecked = event.target as HTMLInputElement;
    emit("update:checked", ischecked.checked);
};
</script>

<style scoped>
.checked-tick:checked {
    background-image: url("https://d5aoblv5p04cg.cloudfront.net/application/images/components/checked-tick.svg");
}

.disabled-tick:checked {
    background-image: url("https://d5aoblv5p04cg.cloudfront.net/application/images/components/disabled-tick.svg");
}

.error-tick:checked {
    background-image: url("https://d5aoblv5p04cg.cloudfront.net/application/images/components/error-tick.svg");
}
</style>
