<template>
    <div class="w-[calc(100vw-40px)] rounded-b-xl shadow-lg md:w-[420px]">
        <div
            class="flex h-11 items-center justify-between rounded-t-xl bg-gray-50"
        >
            <p class="ml-3 text-sm font-medium text-gray-700">
                Messages & events
            </p>
        </div>
        <ul
            v-if="!loading"
            class="max-h-[560px] rounded-b-xl"
            :class="{ 'overflow-y-scroll': data.length > 3 }"
        >
            <li v-for="(announcement, index) in data" :key="index">
                <div
                    class="flex border-t border-gray-200 bg-white pb-4 pt-3"
                    :class="{
                        'rounded-b-xl':
                            data.length - 1 === index && !canFetchMore,
                    }"
                >
                    <div class="mx-3 mt-2">
                        <div
                            class="h-2.5 w-2.5 rounded-full"
                            :class="{
                                'bg-blue-300': !announcement.isRead,
                                'border border-gray-300 ': announcement.isRead,
                            }"
                        ></div>
                    </div>

                    <div
                        class="relative flex w-full justify-between rounded-b-xl"
                    >
                        <div>
                            <div
                                class="inline-block rounded-md px-2 py-[3px] text-xs font-medium capitalize text-gray-700"
                                :class="{
                                    'bg-blue-200': announcement.type === 'info',
                                    'bg-indigo-200':
                                        announcement.type === 'news',
                                    'bg-gray-200':
                                        announcement.type === 'report',
                                    'bg-red-300':
                                        announcement.type === 'critical',
                                    'bg-orange-300':
                                        announcement.type === 'warning',
                                }"
                            >
                                {{ announcement.type }}
                            </div>

                            <p
                                class="mr-3 mt-[3px] text-sm font-bold"
                                :class="{
                                    'text-gray-800': !announcement.isRead,
                                    'text-gray-500': announcement.isRead,
                                }"
                            >
                                {{ announcement.title }}
                            </p>

                            <p
                                class="dynamic-data mr-3 text-sm text-gray-500"
                                v-html="announcement.data"
                            ></p>

                            <a
                                v-if="
                                    announcement.button_text &&
                                    announcement.button_url
                                "
                                :href="announcement.button_url"
                                target="_blank"
                            >
                                <AtomicButton
                                    class="mt-3"
                                    size="sm"
                                    color="white"
                                >
                                    {{ announcement.button_text }}
                                </AtomicButton>
                            </a>
                        </div>

                        <div
                            class="absolute right-0 mr-4 text-xs text-gray-400"
                        >
                            {{
                                formattedPublishTime(
                                    new Date(announcement.created_at)
                                )
                            }}
                            ago
                        </div>
                    </div>
                </div>
            </li>
            <li v-if="data.length === 0">
                <div
                    class="flex rounded-b-xl border-t border-gray-200 bg-white pb-4 pt-3"
                >
                    <div class="mx-3 mt-2">
                        <div class="h-2.5 w-2.5 rounded-full"></div>
                    </div>

                    <div
                        class="relative flex w-full justify-center rounded-b-xl"
                    >
                        <div>
                            <p
                                class="mr-3 mt-[3px] text-sm font-medium text-gray-800"
                            >
                                You have no new messages...
                            </p>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
        <div v-if="loading" class="bg-white py-5">
            <LoadingSpinner size="medium" :delay="false" />
        </div>
        <button
            v-if="canFetchMore"
            class="group inline-flex w-full justify-center rounded-b-xl border-t border-gray-200 bg-gray-50 py-3 transition-all duration-100 hover:bg-gray-100"
            @click="loadMore"
        >
            <span
                class="mr-4 text-sm font-medium text-custom-blue-500 group-hover:opacity-90"
            >
                Show older
            </span>
        </button>
    </div>
</template>

<script lang="ts" setup>
import { onMounted, ref } from "vue";
import { useResource } from "@/composables/useResource";
import IAnnouncement from "@/types/Announcements/IAnnouncement";
import { formatDistance } from "date-fns";
import axios from "axios";
import route from "ziggy-js";

const loading = ref(true);

onMounted(async () => {
    await fetchData();
    loading.value = false;
    try {
        await axios.patch(route("announcements-notifications.update"));
    } catch (err) {
        () => undefined;
    }
});

const { data, fetchData, currentPage, canFetchMore } =
    useResource<IAnnouncement>("/announcements-notifications", {
        perPage: 4,
        hasInfiniteScroll: true,
    });

const formattedPublishTime = (date: Date): string => {
    return formatDistance(date, new Date());
};

const loadMore = async () => {
    currentPage.value += 1;
    await fetchData();
};
</script>

<style>
.dynamic-data a {
    @apply text-custom-blue-500;
}
</style>
