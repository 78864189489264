<template>
    <BasicTabs :tabs="tabs" :tab-key="tabKey">
        <template #header>
            <inertia-link :href="route('kiosk.teams.index')">
                <AtomicButton color="yellow" class="mt-4" size="sm">
                    Back to All Teams
                    <template #icon>
                        <BackspaceIcon
                            class="mr-2 h-6 w-6 transition duration-200 group-hover:text-gray-600"
                        />
                    </template>
                </AtomicButton>
            </inertia-link>

            <TeamTabsProfile :selected-team="selectedTeam" />
        </template>

        <slot />
    </BasicTabs>
</template>

<script lang="ts" setup>
import {
    BackspaceIcon,
    UserGroupIcon,
    AdjustmentsVerticalIcon,
    ShieldExclamationIcon,
} from "@heroicons/vue/24/solid";

import route from "ziggy-js";
import TabItem from "@/types/Settings/TabItem";
import ICurrentTeam from "@/types/Members/ICurrentTeam";

interface TeamTabProps {
    tabKey: string;
    selectedTeam: ICurrentTeam;
}

const props = withDefaults(defineProps<TeamTabProps>(), {
    tabKey: "members",
});

const tabs: TabItem[] = [
    {
        title: "Members",
        key: "members",
        icon: UserGroupIcon,
        url: route("kiosk.teams.members.index", {
            team: props.selectedTeam.id,
        }),
    },
    {
        title: "Features",
        key: "feature-management",
        icon: AdjustmentsVerticalIcon,
        url: route("kiosk.teams.feature-management.index", {
            team: props.selectedTeam.id,
        }),
    },
    {
        title: "Blocked Actions",
        key: "team-blocked-actions",
        icon: ShieldExclamationIcon,
        url: route("kiosk.teams.blocked-actions.index", {
            team: props.selectedTeam.id,
        }),
    },
];
</script>
