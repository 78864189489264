<template>
    <AuthLayout>
        <div
            class="mt-8 w-full overflow-hidden bg-white p-6 shadow-xl sm:mt-14 sm:max-w-md sm:rounded-lg"
        >
            <div class="mb-8 mt-[2px] border-b border-gray-200 pb-5">
                <h1 class="text-center text-xl font-bold text-gray-800">
                    Confirm your password
                </h1>

                <div class="mt-3 text-center text-sm text-gray-500">
                    <div>
                        This is a secure area of the application. Please confirm
                        your password before continuing.
                    </div>
                </div>
            </div>

            <form @submit.prevent="submit">
                <div>
                    <TextInput
                        id="password"
                        v-model="v$.form.password.$model"
                        type="password"
                        required
                        suppress-required-icon
                        autocomplete="current-password"
                        name="password"
                        autofocus
                        label="Password"
                        :error="getError('password')"
                    />
                </div>

                <div class="mt-10 flex flex-col items-center">
                    <MegaButton
                        id="login-button"
                        :disabled="loading"
                        type="submit"
                    >
                        Sign in
                    </MegaButton>

                    <inertia-link
                        :href="route('home')"
                        class="mt-6 pb-2 text-xs font-medium text-custom-blue-500 hover:text-custom-blue-600"
                        >Cancel</inertia-link
                    >
                </div>
            </form>
        </div>
    </AuthLayout>
</template>

<script lang="ts" setup>
import { reactive, ref } from "vue";
import route from "ziggy-js";
import { required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { router } from "@inertiajs/vue3";
import IInputErrors from "@/types/Atomic/IInputErrors";

const state = reactive({
    form: {
        password: "",
    },
});
const rules = {
    form: {
        password: { required },
    },
};
const v$ = useVuelidate(rules, state);

const loading = ref(false);
const errors = ref<IInputErrors>({});

const submit = () => {
    v$.value.form.password.$touch();
    if (v$.value.form.password.$error) {
        () => undefined;
    } else {
        loading.value = true;
        router.post(route("password.confirm"), state.form, {
            preserveScroll: true,
            onError: (err) => {
                errors.value = err;
            },
            onFinish: () => {
                loading.value = false;
                state.form.password = "";
                v$.value.$reset();
            },
        });
    }
};

const getError = (field: string) => {
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ""
    );
};
</script>
