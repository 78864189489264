<template>
    <BasicTabs :tabs="tabs" :tab-key="tabKey">
        <template #header>
            <inertia-link :href="route('kiosk.users.index')">
                <AtomicButton color="yellow" class="mt-4" size="sm">
                    Back to All Users
                    <template #icon>
                        <BackspaceIcon
                            class="mr-2 h-6 w-6 transition duration-200 group-hover:text-gray-600"
                        />
                    </template>
                </AtomicButton>
            </inertia-link>

            <UserTabsProfile
                :selected-user="selectedUser"
                :personal-team="personalTeam"
            />
        </template>
        <slot />
    </BasicTabs>
</template>

<script lang="ts" setup>
import {
    BackspaceIcon,
    UserGroupIcon,
    AdjustmentsVerticalIcon,
    ShieldExclamationIcon,
} from "@heroicons/vue/24/solid";

import { MegaphoneIcon } from "@heroicons/vue/24/outline";

import route from "ziggy-js";
import TabItem from "@/types/Settings/TabItem";
import IUser from "@/types/Members/IUser";
import ICurrentTeam from "@/types/Members/ICurrentTeam";
import { PropType } from "vue";

const props = defineProps({
    tabKey: {
        type: String,
        default: "user-activity",
    },
    selectedUser: {
        type: Object as PropType<IUser>,
        required: true,
    },
    personalTeam: {
        type: Object as PropType<ICurrentTeam>,
        required: true,
    },
});

const tabs: TabItem[] = [
    {
        title: "Teams",
        key: "user-teams",
        icon: UserGroupIcon,
        url: route("kiosk.users.teams.index", { user: props.selectedUser.id }),
    },
    {
        title: "Features",
        key: "feature-management",
        icon: AdjustmentsVerticalIcon,
        url: route("kiosk.users.feature-management.index", {
            user: props.selectedUser.id,
        }),
    },
    {
        title: "Notifications",
        key: "user-notifications",
        icon: MegaphoneIcon,
        url: route("kiosk.users.notifications.index", {
            user: props.selectedUser.id,
        }),
    },
    {
        title: "Blocked Actions",
        key: "user-blocked-actions",
        icon: ShieldExclamationIcon,
        url: route("kiosk.users.blocked-actions.index", {
            user: props.selectedUser.id,
        }),
    },
];
</script>
