<template>
    <div>
        <div
            class="fixed right-7 top-[52px] z-[501] ml-7 md:absolute md:right-20 md:top-14"
        >
            <transition
                enter-active-class="transition duration-200 ease-out"
                enter-from-class="translate-y-1 opacity-0"
                enter-to-class="translate-y-0 opacity-100"
                leave-active-class="transition duration-150 ease-in"
                leave-from-class="translate-y-0 opacity-100"
                leave-to-class="translate-y-1 opacity-0"
            >
                <Announcements
                    v-if="announcementsOpened"
                    v-click-away="closeAnnouncements"
                ></Announcements>
            </transition>
        </div>

        <div
            class="fixed z-[500] flex h-14 w-full items-center justify-between bg-gray-800 md:relative md:h-16"
            @click.self="handleCloseSideBarMobile"
        >
            <div class="flex items-center">
                <inertia-link href="/" class="hidden md:block">
                    <div
                        class="flex h-16 w-16 items-center justify-center bg-gradient-to-br from-[#374151] to-[#4B5563]"
                    >
                        <IconLogo width="32" height="32" class="absolute" />
                    </div>
                </inertia-link>
                <TeamMenu @closeMenuMobile="handleCloseSideBarMobile" />
                <SideBarMobile
                    class="overflow-y-scroll"
                    :opened="sideBarMobileOpened"
                    @close="handleCloseSideBarMobile"
                />
            </div>

            <div class="mr-6 hidden items-center md:flex">
                <inertia-link
                    v-if="globalProps.isAdmin && isInKiosk"
                    :href="route('home')"
                >
                    <div
                        class="mr-6 hidden text-xs font-medium text-white sm:block"
                    >
                        Go Back to App
                    </div>
                </inertia-link>

                <inertia-link
                    v-if="globalProps.isAdmin && !isInKiosk"
                    :href="route('kiosk.users.index')"
                >
                    <div
                        class="mr-6 hidden text-xs font-medium text-white sm:block"
                    >
                        Kiosk
                    </div>
                </inertia-link>

                <a :href="globalProps.homepage_url" target="_blank"
                    ><div
                        class="mr-6 hidden text-xs font-medium text-white sm:block"
                    >
                        Knowledgebase
                    </div></a
                >

                <div class="h-8 w-px bg-[#364152]"></div>

                <OpenAnnouncementButton
                    :announcements-opened="announcementsOpened"
                    :has-unread-announcements="hasUnreadAnnouncements"
                    @toggle-announcements="toggleAnnouncements"
                ></OpenAnnouncementButton>

                <ProfileMenu class="shrink-0" />
            </div>
            <div class="mr-3 flex items-center sm:mr-6 md:hidden">
                <OpenAnnouncementButton
                    :announcements-opened="announcementsOpened"
                    :has-unread-announcements="hasUnreadAnnouncements"
                    @toggle-announcements="toggleAnnouncements"
                ></OpenAnnouncementButton>
                <div
                    class="relative flex h-6 w-6 cursor-pointer items-center justify-center"
                    @click="handleSideBarMobile"
                >
                    <transition
                        enter-active-class="transition ease-in-out duration-800"
                        enter-from-class="transform opacity-0 scale-10"
                        enter-to-class="transform scale-100 opacity-100"
                        leave-active-class="transition duration-200 ease-in"
                        leave-from-class="opacity-100"
                        leave-to-class="opacity-0"
                    >
                        <div v-if="!sideBarMobileOpened" class="absolute">
                            <svg
                                width="24"
                                height="16"
                                viewBox="0 0 24 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M0 0H24V2H0V0ZM0 7H24V9H0V7ZM16.32 14H0V16H16.32V14Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </transition>
                    <transition
                        enter-active-class="transition duration-1000 ease-in-out"
                        enter-from-class="transform rotate-180 opacity-0 scale-80"
                        enter-to-class="transform scale-100 rotate-0 opacity-100"
                        leave-active-class="transition duration-100 ease-in"
                        leave-from-class="transform opacity-100"
                        leave-to-class="transform opacity-0"
                    >
                        <div v-if="sideBarMobileOpened" class="absolute">
                            <svg
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M10.7072 9.29299L1.41421 0L0 1.41421L9.29299 10.7072L4.60148e-05 20.0001L1.41426 21.4144L10.7072 12.1214L19.799 21.2132L21.2132 19.799L12.1214 10.7072L21.2132 1.61537L19.799 0.201156L10.7072 9.29299Z"
                                    fill="#FFC000"
                                />
                            </svg>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { usePage } from "@inertiajs/vue3";
import { IMailClockerPageProps } from "@/types/MailClockerPageProps/MailClockerPageProps";
import { computed, ref } from "vue";
import route from "ziggy-js";

onMounted(() => {
    getHasUnreadAnnouncements();
});

const hasUnreadAnnouncements = ref(false);
const sideBarMobileOpened = ref(false);
const announcementsOpened = ref(false);

const globalProps = computed(() => usePage<IMailClockerPageProps>().props);

const isInKiosk = computed(() => {
    return usePage<IMailClockerPageProps>().url.startsWith("/kiosk");
});

const handleSideBarMobile = () => {
    sideBarMobileOpened.value = !sideBarMobileOpened.value;
};

const getHasUnreadAnnouncements = async () => {
    try {
        const response = await axios.get(
            route("user.has-unread-announcements.index")
        );
        if (response.data.success) {
            hasUnreadAnnouncements.value = response.data.data;
        }
    } catch (error) {
        () => undefined;
    }
};

const toggleAnnouncements = () => {
    if (announcementsOpened.value) {
        announcementsOpened.value = false;
        getHasUnreadAnnouncements();
    } else {
        announcementsOpened.value = true;
    }
};

const closeAnnouncements = () => {
    announcementsOpened.value = false;
    getHasUnreadAnnouncements();
};

const handleCloseSideBarMobile = () => {
    sideBarMobileOpened.value = false;
};
</script>
