<template>
    <div class="py-12">
        <SelectInput
            v-model="span"
            class="mt-5 w-full sm:mr-6 sm:w-80"
            placeholder="Select filter"
            :data="filters"
            label="Filter"
        >
        </SelectInput>

        <DataTable
            class="mt-10"
            :headers="headers"
            :items="lambdaErrors"
            searchable
            title="Lambdas"
        >
            <template #item-actions="{ row }">
                <div class="flex justify-end p-6">
                    <AtomicButton
                        size="sm"
                        color="yellow"
                        @click="openLambdaLog(row)"
                    >
                        <template #icon>
                            <BugAntIcon class="h-6 w-6" />
                        </template>
                    </AtomicButton>
                </div>
            </template>
        </DataTable>
    </div>
</template>

<script lang="ts" setup>
import { BugAntIcon } from "@heroicons/vue/24/outline";
import IPaginationData from "@/components/Atomic/DataTable/types/IPaginationData";
import IDataTableHeader from "@/components/Atomic/DataTable/types/IDataTableHeader";
import ILambda from "@/types/Lambdas/ILambdaError";

const props = defineProps<{
    lambdaErrors: IPaginationData<ILambda>;
    span: number;
}>();

const headers: IDataTableHeader[] = [
    { text: "Function name", id: "function", sortable: false },
    { text: "Errors Count", id: "errors", sortable: false },
    { text: "", id: "actions" },
];

const filters = [
    { title: "-", value: 604800 },
    { title: "30 minutes", value: 1800 },
    { title: "1 hour", value: 3600 },
    { title: "3 hours", value: 10800 },
    { title: "6 hours", value: 21600 },
];

const span = ref(props.span);

watch(span, () => {
    router.visit(route("kiosk.lambdas.index", { span: span.value }));
});

const openLambdaLog = async (lambda: ILambda) => {
    const link =
        "https://eu-central-1.console.aws.amazon.com/cloudwatch/home?region=eu-central-1#logsV2:log-groups/log-group/$252Faws$252Flambda$252F" +
        lambda.function +
        "/log-events$3FfilterPattern$3D$253FERROR+$253Ftimed$26start$3D-" +
        props.span * 1000;
    window.open(link, "_blank");
};
</script>
