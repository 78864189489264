<template>
    <UserTabs
        :selected-user="selectedUser"
        :personal-team="userPersonalTeam"
        tab-key="user-teams"
    >
        <DataTable
            class="mt-12"
            :title="selectedUser.name + `'s Teams `"
            searchable
            :headers="headers"
            :items="userTeams"
        >
            <template #item-joined_at="{ data }">
                <div class="flex justify-center p-6">
                    <div v-if="data">
                        {{ format(new Date(data as string), "d. L. yyyy") }}
                    </div>
                </div>
            </template>

            <template #item-custom="{ row }">
                <div class="flex w-full justify-center gap-2">
                    <AtomicButton
                        color="white"
                        size="sm"
                        @click="handleShowTeam(row as ICurrentTeam)"
                    >
                        <template #icon>
                            <MagnifyingGlassIcon class="h-6 w-6" />
                        </template>
                    </AtomicButton>
                </div>
            </template>
        </DataTable>
    </UserTabs>
</template>
<script lang="ts" setup>
import IDataTableHeader from "@/components/Atomic/DataTable/types/IDataTableHeader";
import IPaginationData from "@/components/Atomic/DataTable/types/IPaginationData";
import ICurrentTeam from "@/types/Members/ICurrentTeam";
import IUser from "@/types/Members/IUser";
import { format } from "date-fns";

import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline";

import { router } from "@inertiajs/vue3";
import route from "ziggy-js";

defineProps<{
    selectedUser: IUser;
    userTeams: IPaginationData<ICurrentTeam>;
    userPersonalTeam: ICurrentTeam;
}>();

const headers: IDataTableHeader[] = [
    {
        text: "Name",
        id: "name",
        sortable: true,
        sortableId: "name",
    },
    {
        text: "Role",
        id: "role",
        sortable: true,
        sortableId: "role",
    },
    {
        text: "Joined at",
        id: "joined_at",
        align: "center",
    },
    {
        text: "Bucket blocked at",
        id: "bucket_blocked_at",
        align: "center",
    },
    {
        text: "",
        id: "custom",
    },
];

const handleShowTeam = function (team: ICurrentTeam) {
    router.get(
        route("kiosk.teams.feature-management.index", {
            team: team.id,
        })
    );
};
</script>
