<template>
    <div>
        <transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="opacity-0"
            enter-to-class="opacity-100"
            leave-active-class="transition duration-200 ease-in"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                v-if="opened"
                class="fixed left-0 top-[56px] z-[498] h-full w-full bg-gray-800 bg-opacity-30 backdrop-blur-[2px] backdrop-filter md:hidden"
                @click="$emit('close')"
            ></div>
        </transition>

        <transition
            enter-active-class="transition duration-500 ease-out delay-100"
            enter-from-class="transform -translate-x-40 opacity-0"
            enter-to-class="transform translate-x-0 opacity-100"
            leave-active-class="transition duration-200 ease-in"
            leave-from-class="transform opacity-100"
            leave-to-class="transform -translate-x-40 opacity-0"
        >
            <div
                v-if="opened"
                class="mt- fixed left-0 top-0 z-[499] mt-14 flex h-full min-h-screen w-full max-w-[600px] shrink-0 flex-col bg-gray-100 pb-14 md:hidden"
            >
                <div class="flex grow flex-col overflow-y-scroll">
                    <nav
                        class="border-r-1 border-gray-200"
                        aria-label="Sidebar"
                        role="navigation"
                    >
                        <div
                            class="border-b-1 border-gray-200 bg-white px-2 py-5"
                        >
                            <inertia-link
                                v-for="link in links"
                                :key="link.href"
                                :href="link.href"
                                :aria-current="
                                    page.startsWith(link.id) ? 'page' : null
                                "
                                :class="{
                                    'bg-gray-200 text-gray-600':
                                        page.startsWith(link.id),
                                    'text-gray-500': !page.startsWith(link.id),
                                }"
                                class="group flex items-center justify-between rounded-md bg-white text-sm font-medium transition duration-200 hover:bg-gray-200 hover:text-gray-600 xl:w-auto"
                                @click="$emit('close')"
                            >
                                <div class="flex items-center py-4 pl-3">
                                    <component
                                        :is="link.icon"
                                        class="mr-3 h-6 w-6 transition duration-200 group-hover:text-gray-600"
                                        :class="{
                                            'text-gray-400': !page.startsWith(
                                                link.id
                                            ),
                                        }"
                                    ></component>

                                    <p>
                                        {{ link.name }}
                                    </p>
                                </div>
                                <div
                                    v-if="link.count != null"
                                    class="mr-4 flex h-5 w-[37px] items-center justify-center rounded-2lg bg-gray-100 text-xs font-normal text-gray-500"
                                >
                                    {{ link.count }}
                                </div>
                            </inertia-link>
                        </div>

                        <div
                            class="bg-gray-100 px-2 pb-[23px]"
                            @click="$emit('close')"
                        >
                            <inertia-link
                                v-if="isAdmin && !isInKiosk"
                                :href="route('kiosk.users.index')"
                            >
                                <div
                                    class="border-b border-gray-200 py-4 pl-5 text-base font-medium text-gray-500"
                                >
                                    Kiosk
                                </div>
                            </inertia-link>

                            <inertia-link
                                v-if="isAdmin && isInKiosk"
                                :href="route('home')"
                            >
                                <div
                                    class="border-b border-gray-200 py-4 pl-5 text-base font-medium text-gray-500"
                                >
                                    Back to App
                                </div>
                            </inertia-link>

                            <inertia-link
                                href="/logout"
                                method="POST"
                                as="button"
                            >
                                <div
                                    class="border-b border-gray-200 py-4 pl-5 text-base font-medium text-gray-500"
                                >
                                    Logout
                                </div>
                            </inertia-link>
                        </div>
                    </nav>
                </div>
            </div>
        </transition>
    </div>
</template>

<script lang="ts" setup>
import { usePage } from "@inertiajs/vue3";
import { computed } from "vue";
import route from "ziggy-js";
import { IMailClockerPageProps } from "@/types/MailClockerPageProps/MailClockerPageProps";

defineProps({
    opened: {
        type: Boolean,
        default: false,
    },
});

defineEmits(["close"]);

const { links } = sidebar();
const page = computed(() => usePage<IMailClockerPageProps>().url);
const { isAdmin } = usePermissions();

const globalProps = computed(() => usePage<IMailClockerPageProps>().props);

const isInKiosk = computed(() => {
    return globalProps.value.url.startsWith("/kiosk");
});
</script>

<style></style>
