<template>
    <div class="py-12">
        <inertia-link :href="route('kiosk.announcements.index')">
            <AtomicButton color="yellow" size="sm">
                Back to Announcements
                <template #icon>
                    <BackspaceIcon
                        class="mr-2 h-6 w-6 transition duration-200 group-hover:text-gray-600"
                    />
                </template>
            </AtomicButton>
        </inertia-link>

        <h1
            class="mt-2 text-2xl font-medium leading-normal tracking-small text-gray-800"
        >
            Create a New Announcement
        </h1>

        <form class="flex flex-col" @submit.prevent="saveAnnouncement">
            <TextInput
                v-model="v$.form.title.$model"
                class="my-6 w-52 sm:w-80"
                label="Title"
                type="text"
                placeholder="max 255 characters"
                :error="getError('title')"
            />

            <TextInput
                v-model="state.form.publishedAt"
                label="Date"
                type="datetime-local"
                class="mb-6 w-40"
            />

            <SelectInput
                v-model="state.form.type"
                class="mb-6 w-52 sm:w-80"
                :data="types"
                label="Type of announcement"
                placeholder="Type"
                :error="getError('type')"
            />

            <EditorTinyMce
                v-model="v$.form.data.$model"
                api-key="y01dz3hyuzk77t9a3vl38gmcb3v62v3ft8pb8v2kpjuyyequ"
                :init="{
                    height: 500,
                    menubar: false,
                    plugins: [
                        'advlist autolink lists link image charmap print preview anchor',
                        'searchreplace visualblocks code fullscreen',
                        'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                        'undo redo | formatselect | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help',
                }"
            />

            <div v-if="getError('data')">
                <div class="mt-2 text-sm text-custom-red-600">
                    {{ getError("data") }}
                </div>
            </div>

            <TextInput
                v-model="v$.form.buttonText.$model"
                class="mt-6 w-52 sm:w-80"
                label="Button Text"
                type="text"
                placeholder="max 255 characters"
                :error="getError('buttonText')"
            />

            <TextInput
                v-model="v$.form.buttonUrl.$model"
                class="mt-6 w-52 sm:w-80"
                label="Button URL"
                type="text"
                placeholder="max 2000 characters"
                :error="getError('buttonUrl')"
            />

            <AtomicButton
                type="submit"
                color="yellow"
                size="sm"
                class="mt-4 w-max"
                :disabled="loading || v$.$invalid"
            >
                Save
            </AtomicButton>
        </form>
    </div>
</template>

<script lang="ts" setup>
import EditorTinyMce from "@tinymce/tinymce-vue";
import useVuelidate from "@vuelidate/core";
import { BackspaceIcon } from "@heroicons/vue/24/outline";
import { required, maxLength } from "@vuelidate/validators";
import route from "ziggy-js";
import IInputErrors from "@/types/Atomic/IInputErrors";

const state = reactive({
    form: {
        title: "",
        publishedAt: "",
        type: "news",
        data: "",
        buttonText: "",
        buttonUrl: "",
    },
});

const rules = {
    form: {
        title: {
            maxLength: maxLength(255),
        },
        data: {
            required,
            maxLength: maxLength(5000),
        },
        buttonText: {
            maxLength: maxLength(255),
        },
        buttonUrl: {
            maxLength: maxLength(2000),
        },
    },
};

const v$ = useVuelidate(rules, state);

const loading = ref(false);
const types = [
    { title: "News", value: "news" },
    { title: "Report", value: "report" },
    { title: "Warning", value: "warning" },
    { title: "Critical", value: "critical" },
];
const errors = ref<IInputErrors>({});

const saveAnnouncement = () => {
    v$.value.$touch();
    if (v$.value.form.$error) {
        () => undefined;
    } else {
        loading.value = true;
        router.post(route("kiosk.announcements.store"), state.form, {
            preserveScroll: false,
            onError: (err) => {
                errors.value = err;
            },
            onSuccess: () => {
                errors.value = {};
            },
            onFinish: () => {
                loading.value = false;
            },
        });
    }
};

const getError = (field: string) => {
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ""
    );
};
</script>

<style></style>
