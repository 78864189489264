import { ref } from "vue";
import axios from "axios";
import route from "ziggy-js";
import ICurrentTeam from "@/types/Members/ICurrentTeam";

const allTeams = ref<ICurrentTeam[]>([]);
const membersCount = ref(undefined);

export const useTeamsAndMembers = () => {
    const fetchTeams = async () => {
        try {
            const response = await axios.get(route("user.all-teams.index"));

            if (response.data.success) {
                allTeams.value = Object.values(response.data.data);
            }
        } catch (err) {
            () => undefined;
        }
    };

    async function getCurrentTeamMembersCount() {
        try {
            const response = await axios.get(route("user.members-count.index"));

            if (response.data.success) {
                membersCount.value = response.data.data;
            }
        } catch (err) {
            () => undefined;
        }
    }

    return {
        fetchTeams,
        getCurrentTeamMembersCount,
        membersCount,
        allTeams,
    };
};

export default useTeamsAndMembers;
