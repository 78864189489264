import INotification from "@/types/Notifications/INotifications";
import { ref } from "vue";

export const notifications = ref<INotification[]>([]);
let id = 0;

export const create = ({
    title,
    text,
    type = "info",
    persistent = false,
}: {
    title: string;
    text?: string;
    type: "error" | "success" | "info" | "warning";
    persistent?: boolean;
}) => {
    const notification: INotification = {
        id: id++,
        title: title,
        text: text,
        type: type,
        persistent: persistent,
    };

    notifications.value.push(notification);

    if (!notification.persistent) {
        setTimeout(() => {
            notifications.value = notifications.value.filter(
                (n) => n.id !== notification.id
            );
        }, 5000);
    }
};

export const remove = (notificationId: number) => {
    notifications.value = notifications.value.filter(
        (n) => n.id !== notificationId
    );
};
