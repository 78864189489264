<template>
    <TextInput
        class="w-full lg:w-[350px] xl:w-[476px]"
        :model-value="modelValue"
        :placeholder="placeholder"
        :disabled="disabled"
        :error="error"
        @update:modelValue="onInput"
    >
        <template #icon-left>
            <MagnifyingGlassIcon class="h-5 w-5" />
        </template>

        <template #icon-right>
            <span
                v-if="modelValue"
                class="cursor-pointer text-sm text-gray-500"
                @click="cancelSearch"
            >
                <svg
                    class="h-3 w-3"
                    width="8"
                    height="8"
                    viewBox="0 0 8 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.75403 0.55546C1.59686 0.403662 1.38636 0.319666 1.16786 0.321565C0.949365 0.323463 0.740354 0.411104 0.585847 0.565611C0.43134 0.720118 0.343699 0.929129 0.341801 1.14763C0.339902 1.36612 0.423898 1.57662 0.575696 1.73379L2.83171 3.9898L0.575696 6.24582C0.496104 6.32269 0.432619 6.41464 0.388945 6.51631C0.345271 6.61798 0.322282 6.72733 0.321321 6.83798C0.320359 6.94863 0.341444 7.05836 0.383344 7.16078C0.425245 7.26319 0.487123 7.35623 0.565367 7.43448C0.643611 7.51272 0.736654 7.5746 0.839068 7.6165C0.941481 7.6584 1.05121 7.67949 1.16186 7.67852C1.27251 7.67756 1.38186 7.65457 1.48353 7.6109C1.5852 7.56723 1.67716 7.50374 1.75403 7.42415L4.01004 5.16814L6.26605 7.42415C6.42322 7.57595 6.63372 7.65994 6.85222 7.65804C7.07072 7.65614 7.27973 7.5685 7.43424 7.414C7.58874 7.25949 7.67638 7.05048 7.67828 6.83198C7.68018 6.61348 7.59619 6.40298 7.44439 6.24582L5.18837 3.9898L7.44439 1.73379C7.59619 1.57662 7.68018 1.36612 7.67828 1.14763C7.67638 0.929128 7.58874 0.720118 7.43424 0.565611C7.27973 0.411104 7.07072 0.323463 6.85222 0.321565C6.63372 0.319666 6.42322 0.403662 6.26605 0.55546L4.01004 2.81147L1.75403 0.55546Z"
                        fill="currentColor"
                    />
                </svg>
            </span>
        </template>
    </TextInput>
</template>

<script lang="ts" setup>
import { MagnifyingGlassIcon } from "@heroicons/vue/24/solid";

defineProps({
    placeholder: {
        type: String,
        default: "",
    },
    modelValue: {
        type: String,
        default: "",
    },
    error: {
        type: String,
        default: "",
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(["update:modelValue"]);

const onInput = (v: string) => {
    emit("update:modelValue", v);
};

const cancelSearch = () => {
    emit("update:modelValue", "");
};
</script>

<style></style>
