<template>
    <SettingsTabs tab-key="profile-settings">
        <div class="mb-[98px]">
            <div
                class="mt-[44px] grid-cols-layout border-b border-gray-300 pb-5 lg:grid"
                @submit.prevent="updateNameAndEmail"
            >
                <div class="mb-6 lg:mb-0 lg:mt-4">
                    <h3
                        class="gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
                    >
                        Contact information
                    </h3>
                    <p class="max-w-xs text-sm text-gray-500">
                        Please enter your full name and correct e-mail address.
                        Your name will be displayed when you invite someone else
                        to collaborate with you or your team.
                    </p>
                </div>

                <form
                    class="mx-auto w-full rounded-md bg-white shadow-card lg:mx-0"
                >
                    <div class="flex flex-wrap border-b border-gray-200 pl-6">
                        <TextInput
                            v-model="v$.form.profileInfo.name.$model"
                            class="mr-6 mt-6 w-full sm:w-80"
                            label="Name and Surname"
                            type="text"
                            :error="getProfileError('name')"
                        />

                        <TextInput
                            v-model="v$.form.profileInfo.email.$model"
                            class="my-6 mr-6 w-full sm:w-80"
                            label="E-mail address"
                            :error="getProfileError('email')"
                            required
                        />
                    </div>

                    <div class="flex justify-end">
                        <div class="my-3 pr-6">
                            <AtomicButton
                                type="submit"
                                color="yellow"
                                size="sm"
                                :disabled="
                                    !v$.form.profileInfo.$anyDirty || loading
                                "
                            >
                                Save changes
                            </AtomicButton>
                        </div>
                    </div>
                </form>
            </div>

            <div
                class="mt-5 grid-cols-layout border-b border-gray-300 pb-5 lg:grid"
            >
                <div class="mb-6 lg:mb-0 lg:mt-4">
                    <h3
                        class="gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
                    >
                        Profile photo
                    </h3>
                    <p class="max-w-xs text-sm text-gray-500">
                        Update your profile photo here.
                    </p>
                </div>

                <div class="mx-auto w-full rounded-md bg-white shadow-card">
                    <div class="flex flex-wrap items-center py-6 pl-6">
                        <div
                            class="mr-5 h-[72px] w-[72px] overflow-hidden rounded-full ring-1 ring-gray-300"
                        >
                            <img
                                :src="
                                    imgUrl
                                        ? imgUrl
                                        : 'https://d5aoblv5p04cg.cloudfront.net/application/images/profile-blank.png'
                                "
                                class="h-[72px] w-[72px] rounded-full object-cover"
                            />
                            no
                            <div v-if="imgUrl">aj</div>
                        </div>

                        <div class="relative">
                            <AtomicButton
                                class="mr-4 mt-5 sm:mt-0"
                                color="white"
                                size="md"
                                @click="clickToUpload"
                            >
                                Upload profile photo
                            </AtomicButton>
                            <input
                                id="profileImageUpload"
                                ref="fileProfilePhoto"
                                type="file"
                                accept="image/*"
                                class="absolute block w-full opacity-0"
                                @change="addPhoto"
                            />
                        </div>

                        <button
                            v-if="imgUrl && !imgUrl.includes('ui-avatars.com')"
                            class="mr-4 mt-5 flex pl-2 focus:outline-none sm:mr-0 sm:mt-0"
                            @click="removePhoto"
                        >
                            <svg
                                class="text-gray-400"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M4.29303 4.29302C4.48056 4.10555 4.73487 4.00023 5.00003 4.00023C5.26519 4.00023 5.5195 4.10555 5.70703 4.29302L10 8.58602L14.293 4.29302C14.3853 4.19751 14.4956 4.12133 14.6176 4.06892C14.7396 4.01651 14.8709 3.98892 15.0036 3.98777C15.1364 3.98662 15.2681 4.01192 15.391 4.0622C15.5139 4.11248 15.6255 4.18673 15.7194 4.28062C15.8133 4.37452 15.8876 4.48617 15.9379 4.60907C15.9881 4.73196 16.0134 4.86364 16.0123 4.99642C16.0111 5.1292 15.9835 5.26042 15.9311 5.38242C15.8787 5.50443 15.8025 5.61477 15.707 5.70702L11.414 10L15.707 14.293C15.8892 14.4816 15.99 14.7342 15.9877 14.9964C15.9854 15.2586 15.8803 15.5094 15.6948 15.6948C15.5094 15.8802 15.2586 15.9854 14.9964 15.9877C14.7342 15.99 14.4816 15.8892 14.293 15.707L10 11.414L5.70703 15.707C5.51843 15.8892 5.26583 15.99 5.00363 15.9877C4.74143 15.9854 4.49062 15.8802 4.30521 15.6948C4.1198 15.5094 4.01463 15.2586 4.01236 14.9964C4.01008 14.7342 4.11087 14.4816 4.29303 14.293L8.58603 10L4.29303 5.70702C4.10556 5.51949 4.00024 5.26518 4.00024 5.00002C4.00024 4.73486 4.10556 4.48055 4.29303 4.29302Z"
                                    fill="currentColor"
                                />
                            </svg>
                            <p class="pl-2 text-sm font-medium text-gray-600">
                                Remove photo
                            </p>
                        </button>
                    </div>
                </div>
            </div>

            <div
                class="mt-5 grid-cols-layout border-b border-gray-300 pb-5 lg:grid"
            >
                <div class="mb-6 lg:mb-0 lg:mt-4">
                    <h3
                        class="gray-800 mb-[5px] text-lg font-medium leading-normal tracking-small"
                    >
                        Password
                    </h3>
                    <p class="max-w-xs text-sm text-gray-500">
                        Change your password here.
                    </p>
                </div>

                <form
                    class="mx-auto w-full rounded-md bg-white shadow-card lg:mx-0"
                    @submit.prevent="updatePassword"
                >
                    <div class="flex flex-col border-b border-gray-200 pl-6">
                        <TextInput
                            v-model.trim="state.form.password.current_password"
                            class="mt-5 w-full pr-6 sm:w-80 sm:pr-0"
                            label="Current password"
                            type="password"
                            placeholder="Enter your current password"
                            :error="getPasswordError('current_password')"
                            @change="v$.form.password.current_password.$touch()"
                        />

                        <TextInput
                            v-model.trim="state.form.password.password"
                            class="mt-6 w-full pr-6 sm:w-80 sm:pr-0"
                            label="New password"
                            type="password"
                            placeholder="Enter your new password"
                            :error="getPasswordError('password')"
                            @change="v$.form.password.password.$touch()"
                        />
                        <TextInput
                            v-model.trim="
                                state.form.password.password_confirmation
                            "
                            class="my-6 w-full pr-6 sm:w-80 sm:pr-0"
                            label="Confirm new password"
                            type="password"
                            placeholder="Enter your new password again"
                            :error="getPasswordError('password_confirmation')"
                            @change="
                                v$.form.password.password_confirmation.$touch()
                            "
                        />
                    </div>
                    <div class="flex justify-end">
                        <div class="my-3 pr-6">
                            <AtomicButton
                                type="submit"
                                color="yellow"
                                size="sm"
                                :disabled="v$.form.password.$invalid || loading"
                            >
                                Update password
                            </AtomicButton>
                        </div>
                    </div>
                </form>
            </div>

            <!-- <PersonalApiToken /> -->

            <TwoFactorAuthentication />
        </div>
    </SettingsTabs>
</template>

<script lang="ts" setup>
import useVuelidate from "@vuelidate/core";
import { router } from "@inertiajs/vue3";
import route from "ziggy-js";
import { create } from "@composables/useNotifications";
import { usePage } from "@inertiajs/vue3";

import {
    required,
    email,
    sameAs,
    minLength,
    helpers,
    maxLength,
} from "@vuelidate/validators";
import { IMailClockerPageProps } from "@/types/MailClockerPageProps/MailClockerPageProps";
import IInputErrors from "@/types/Atomic/IInputErrors";

const globalProps = usePage<IMailClockerPageProps>().props;
const imgUrl = ref("");
const img = ref<File | null>(null);
const fileProfilePhoto = ref<HTMLElement | null>(null);
const errors = ref<IInputErrors>({});
const loading = ref(false);
const { fetchTeams } = useTeamsAndMembers();

const state = reactive({
    form: {
        profileInfo: {
            name: "",
            email: "",
        },
        password: {
            current_password: "",
            password: "",
            password_confirmation: "",
        },
    },
});

const passwordRef = computed(() => state.form.password.password);

const rules = {
    form: {
        profileInfo: {
            name: {
                required,
                maxLength: maxLength(255),
            },
            email: {
                required,
                email,
                maxLength: maxLength(255),
            },
        },
        password: {
            current_password: {},
            password: {
                required,
                minLength: minLength(8),
            },
            password_confirmation: {
                required,
                sameAsPasswordNew: helpers.withMessage(
                    "Passwords do not match",
                    sameAs(passwordRef)
                ),
            },
        },
    },
};
const v$ = useVuelidate(rules, state);

onMounted(() => {
    state.form.profileInfo.name = globalProps.user.name;
    state.form.profileInfo.email = globalProps.user.email;
    if (globalProps.user.profile_photo_url) {
        imgUrl.value = globalProps.user.profile_photo_url;
    }
});

const updateNameAndEmail = () => {
    v$.value.form.profileInfo.$touch();
    if (v$.value.form.profileInfo.$error) {
        () => undefined;
    } else {
        loading.value = true;

        router.put(
            route("settings.profile-settings.update"),
            state.form.profileInfo,
            {
                preserveScroll: true,
                onSuccess: () => {
                    errors.value = {};
                    create({
                        title: "Profile updated",
                        type: "success",
                    });
                    v$.value.form.profileInfo.$reset();
                },
                onError: (err) => {
                    errors.value = err;
                    create({
                        title: "Profile not updated",
                        text: "There was an error updating your profile.",
                        type: "error",
                    });
                },
                onFinish: () => {
                    loading.value = false;
                },
            }
        );
    }
};

const addPhoto = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const files = target.files as FileList;
    img.value = files[0];
    imgUrl.value = URL.createObjectURL(img.value);
    let formData = new FormData();
    formData.append("photo", img.value);
    formData.append("_method", "put");
    router.post(route("settings.profile-settings.update"), formData, {
        preserveScroll: true,
        onSuccess: () => {
            create({
                title: "Profile photo updated",
                type: "success",
            });
            fetchTeams();
        },
        onError: () => {
            create({
                title: "Photo not updated",
                text: "There was an error updating your photo.",
                type: "error",
            });
        },
    });
};

const removePhoto = () => {
    const input = document.querySelector(
        "#profileImageUpload"
    ) as HTMLInputElement;

    if (input !== null) {
        input.value = "";
    }

    img.value = null;
    imgUrl.value = "";

    router.delete(route("settings.profile-settings.destroy"), {
        preserveScroll: true,
        onSuccess: () => {
            create({
                title: "Profile photo updated",
                type: "success",
            });
            fetchTeams();
        },
        onError: () => {
            create({
                title: "Photo not updated",
                text: "There was an error updating your photo.",
                type: "error",
            });
        },
    });
};

const getProfileError = (field: string) => {
    return (
        v$.value.form.profileInfo[field]?.$errors[0]?.$message ||
        errors.value?.updateProfileInformation?.[field] ||
        ""
    );
};

const getPasswordError = (field: string) => {
    return (
        v$.value.form.password[field]?.$errors[0]?.$message ||
        errors.value?.updatePassword?.[field] ||
        ""
    );
};

const updatePassword = () => {
    v$.value.form.password.$touch();
    if (v$.value.form.password.$error) {
        () => undefined;
    } else {
        loading.value = true;

        router.put(
            route("settings.profile-settings.update"),
            state.form.password,
            {
                preserveScroll: true,
                onSuccess: () => {
                    (state.form.password = {
                        current_password: "",
                        password: "",
                        password_confirmation: "",
                    }),
                        v$.value.form.password.$reset();

                    create({
                        title: "Password updated",
                        text: "Your password has been successfully updated.",
                        type: "success",
                    });
                },
                onError: (err) => {
                    errors.value = err;
                    create({
                        title: "Password not updated",
                        text: "There was an error updating your password.",
                        type: "error",
                    });
                },
                onFinish: () => {
                    loading.value = false;
                },
            }
        );
    }
};
const clickToUpload = () => {
    const element = fileProfilePhoto.value as HTMLInputElement;
    element.click();
};
</script>

<style></style>
