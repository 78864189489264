<template>
    <nav
        class="relative z-0 my-3 inline-flex -space-x-px rounded-md shadow-sm"
        aria-label="Pagination"
    >
        <button
            :disabled="prevDisabled"
            class="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-300 disabled:hover:bg-gray-50"
            @click="changePage(props.items.current_page - 1)"
        >
            <span class="sr-only">Previous</span>
            <ChevronLeftIcon class="h-5 w-5" aria-hidden="true" />
        </button>

        <div
            v-if="props.items.last_page > 9 && props.items.current_page > 4"
            class="inline-flex -space-x-px"
        >
            <button
                class="relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 md:inline-flex"
                @click="changePage(1)"
            >
                1
            </button>
            <span
                v-if="props.items.current_page - 2 > 1"
                class="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700"
            >
                ...
            </span>
            <button
                v-if="props.items.current_page - 2 > 1"
                class="relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 md:inline-flex"
                @click="changePage(props.items.current_page - 2)"
            >
                {{ props.items.current_page - 2 }}
            </button>
            <button
                class="relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 md:inline-flex"
                @click="changePage(props.items.current_page - 1)"
            >
                {{ props.items.current_page - 1 }}
            </button>

            <button
                class="bg-indigo-50 relative z-10 inline-flex items-center border border-indigo-500 px-4 py-2 text-sm font-medium text-indigo-600"
                @click="changePage(props.items.current_page)"
            >
                {{ props.items.current_page }}
            </button>
            <button
                v-if="props.items.current_page + 1 < props.items.last_page"
                class="relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 md:inline-flex"
                @click="changePage(props.items.current_page + 1)"
            >
                {{ props.items.current_page + 1 }}
            </button>
            <button
                v-if="props.items.current_page + 2 < props.items.last_page"
                class="relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 md:inline-flex"
                @click="changePage(props.items.current_page + 2)"
            >
                {{ props.items.current_page + 2 }}
            </button>
            <span
                v-if="props.items.current_page + 3 < props.items.last_page"
                class="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700"
            >
                ...
            </span>
            <button
                v-if="props.items.current_page < props.items.last_page"
                class="relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 md:inline-flex"
                @click="changePage(props.items.last_page)"
            >
                {{ props.items.last_page }}
            </button>
        </div>

        <div v-if="props.items.last_page < 10" class="inline-flex -space-x-px">
            <button
                v-for="i in props.items.last_page"
                :key="i"
                class="relative hidden items-center border px-4 py-2 text-sm font-medium md:inline-flex"
                :class="[
                    props.items.current_page === i
                        ? 'bg-indigo-50 z-10 border-indigo-500 text-indigo-600'
                        : 'border-gray-300 bg-white text-gray-500 hover:bg-gray-50 ',
                ]"
                @click="changePage(i)"
            >
                {{ i }}
            </button>
        </div>

        <div
            v-if="props.items.last_page > 9 && props.items.current_page < 5"
            class="-space-x-px"
        >
            <button
                v-for="i in 7"
                :key="i"
                class="relative hidden items-center border px-4 py-2 text-sm font-medium md:inline-flex"
                :class="[
                    props.items.current_page === i
                        ? 'bg-indigo-50 z-10 border-indigo-500 text-indigo-600'
                        : 'border-gray-300 bg-white text-gray-500 hover:bg-gray-50 ',
                ]"
                @click="changePage(i)"
            >
                {{ i }}
            </button>

            <span
                class="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700"
            >
                ...
            </span>

            <button
                class="relative hidden items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 md:inline-flex"
                @click="changePage(props.items.last_page)"
            >
                {{ props.items.last_page }}
            </button>
        </div>
        <button
            :disabled="nextDisabled"
            class="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-300 disabled:hover:bg-gray-50"
            @click="changePage(props.items.next_page)"
        >
            <span class="sr-only">Next</span>
            <ChevronRightIcon class="h-5 w-5" aria-hidden="true" />
        </button>
    </nav>
</template>

<script lang="ts" setup>
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/vue/24/solid";
import { computed } from "vue";

import IPaginationData from "../../types/IPaginationData";

const props = defineProps<{
    items: IPaginationData<unknown>;
}>();

const emit = defineEmits<{
    (e: "change-page", newPage: number | undefined): void;
}>();

const changePage = (newPage: number | undefined) => {
    emit("change-page", newPage);
};

const prevDisabled = computed((): boolean => {
    return props.items.current_page - 1 < 1;
});

const nextDisabled = computed((): boolean => {
    return props.items.current_page + 1 > props.items.last_page;
});
</script>
