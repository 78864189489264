<template>
    <div class="py-12">
        <DataTable
            title="Users"
            searchable
            :headers="headers"
            :items="users"
            :filters="filters"
        >
            <template #item-profile_photo_url="{ data }">
                <div class="flex justify-center">
                    <img
                        v-if="data"
                        class="h-10 w-10 rounded-full object-cover"
                        :src="data"
                    />
                    <img
                        v-else
                        class="h-10 w-10 rounded-full object-cover"
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/No_image_available.svg/600px-No_image_available.svg.png"
                    />
                </div>
            </template>

            <template #item-custom="{ row }">
                <div class="flex w-full justify-center">
                    <AtomicButton
                        color="white"
                        size="sm"
                        @click="handleShowUser(row)"
                    >
                        <template #icon>
                            <MagnifyingGlassIcon class="h-6 w-6" />
                        </template>
                    </AtomicButton>
                </div>
            </template>
        </DataTable>
    </div>
</template>

<script lang="ts" setup>
import IPaginationData from "@/types/Atomic/IPaginationData";
import { MagnifyingGlassIcon } from "@heroicons/vue/24/outline";
import route from "ziggy-js";
import IUser from "@/types/Members/IUser";
import IDataTableHeader from "@/components/Atomic/DataTable/types/IDataTableHeader";
import { IDataTableFilter } from "@/components/Atomic/DataTable/types/IDataTableFilter";
import { router } from "@inertiajs/vue3";

defineProps<{
    users: IPaginationData<IUser>;
}>();

const headers: IDataTableHeader[] = [
    { text: "", id: "profile_photo_url" },
    {
        text: "Name",
        id: "name",
        sortable: true,
        sortableId: "name",
    },
    {
        text: "Email",
        id: "email",
        sortable: true,
        sortableId: "email",
    },
    { text: "", id: "custom" },
];

const filters: IDataTableFilter[] = [
    {
        column: "type",
        label: "User Type",
        values: [
            {
                label: "Regular users",
                value: "regular",
            },
            {
                label: "Admins",
                value: "admin",
            },
        ],
    },
    {
        column: "trial",
        label: "Trial",
        values: [
            {
                label: "PRO Trial",
                value: "pro_trial_ends_at",
            },
            {
                label: "Plugin Trial",
                value: "plugin_trial_ends_at",
            },
        ],
    },
];

const handleShowUser = function (user: IUser) {
    router.get(
        route("kiosk.users.teams.index", {
            user: user.id,
        })
    );
};
</script>

<style></style>
