<template>
    <Modal size="xl" @close="closeModal">
        <template #header>
            <div class="text-gray-600">Pairing QR Code</div>
        </template>

        <template #body>
            <div class="w-full px-[26px] pb-6 pt-[26px]">
                <div class="max-w-[600px] text-sm text-gray-500">
                    <span class="font-medium"
                        >Two-Factor Authentication is enabled.</span
                    >
                    You can pair another device or app as authenticator by
                    scanning QR code below.
                </div>
                <div
                    class="mt-7 flex flex-col items-center justify-start sm:flex-row sm:items-start"
                >
                    <div class="flex items-center justify-center sm:mt-[6px]">
                        <transition
                            enter-active-class="transition duration-1000 ease-out transform"
                            enter-from-class="scale-95 opacity-0"
                            enter-to-class="scale-100 opacity-100"
                            leave-active-class="transition duration-75 ease-in transform"
                            leave-from-class="scale-100 opacity-100"
                            leave-to-class="scale-95 opacity-0"
                        >
                            <div
                                v-if="paringCodes.qrCode"
                                class="h-[150px] w-[150px] rounded-md border p-[17px]"
                                v-html="paringCodes.qrCode"
                            ></div>
                        </transition>

                        <div
                            v-if="!paringCodes.qrCode"
                            class="flex h-[150px] w-[150px] items-center justify-center rounded-md border p-[17px]"
                        >
                            <svg
                                class="h-6 w-6 animate-spin text-gray-600"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <circle
                                    class="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    stroke-width="4"
                                ></circle>
                                <path
                                    class="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                ></path>
                            </svg>
                        </div>
                    </div>

                    <div
                        class="mt-5 flex flex-col items-center justify-center sm:mt-0 sm:items-start sm:pl-6"
                    >
                        <p class="text-base font-medium text-gray-800">
                            Scan the QR Code
                        </p>
                        <p class="mt-2 text-sm text-gray-500 sm:mt-0">
                            Open the
                            <a
                                target="_blank"
                                class="font-medium text-custom-blue-500 hover:text-custom-blue-600"
                                href="https://authy.com/download/"
                                >Authenticator App</a
                            >
                            , tap the “+” and scan the image<br />on the left
                            using your phone or tablet.
                        </p>

                        <p class="mt-6 text-sm text-gray-600">
                            <span class="font-medium text-gray-800"
                                >Manual Entry Key:</span
                            ><br />
                            {{ paringCodes.key }}
                        </p>
                    </div>
                </div>
            </div>
        </template>

        <template #footer>
            <div class="flex justify-between border-t border-gray-200 p-6">
                <AtomicButton color="white" size="sm" @click="closeModal">
                    Cancel
                </AtomicButton>

                <AtomicButton color="yellow" size="sm" @click="closeModal">
                    Finish
                </AtomicButton>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts" setup>
import useParingCodes from "../composable/usePairingCodes";

const emit = defineEmits<{
    (e: "close"): void;
}>();

const { paringCodes } = useParingCodes(
    "auth.two-factor-authentication-pairing-codes"
);

const closeModal = () => {
    emit("close");
};
</script>
