<template>
    <div class="flex items-center justify-center">
        <transition
            enter-active-class="transition duration-150 ease-out transform"
            enter-from-class="scale-0 opacity-0"
            enter-to-class="scale-100 opacity-100"
            leave-active-class="transition duration-100 ease-in transform"
            leave-from-class="scale-100 opacity-100"
            leave-to-class="scale-95 opacity-0"
        >
            <div v-if="shown">
                <svg
                    class="animate-spin text-gray-400"
                    :class="[
                        size === 'large' ? 'h-16 w-16' : '',
                        size === 'medium' ? 'h-8 w-8' : '',
                        size === 'small' ? 'h-4 w-4' : '',
                    ]"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                >
                    <circle
                        class="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        stroke-width="4"
                    ></circle>
                    <path
                        class="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                </svg>
            </div>
        </transition>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";

interface Props {
    size?: "large" | "medium" | "small";
    delay?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
    size: "medium",
    delay: true,
});

//Show the loading spinner after some delay to improve UX.
//We use delay here to prevent jumpy behaviour when loading is only 50ms for example.
const SHOW_LOADING_AFTER = props.delay ? 250 : 0;

const shown = ref(false);

onMounted(() => {
    setTimeout(() => {
        shown.value = true;
    }, SHOW_LOADING_AFTER);
});
</script>
