<template>
    <AuthLayout>
        <div
            class="mt-8 w-full overflow-hidden bg-white p-6 shadow-xl sm:mt-14 sm:max-w-md sm:rounded-lg"
        >
            <div class="mb-8 mt-[2px] border-b border-gray-200 pb-5">
                <h1 class="text-center text-xl font-bold text-gray-800">
                    Create new password
                </h1>
            </div>
            <form @submit.prevent="submit">
                <div>
                    <TextInput
                        id="email"
                        v-model="state.form.email"
                        type="email"
                        required
                        autofocus
                        suppress-required-icon
                        label="Email address"
                        placeholder="Your email address"
                        name="email"
                        autocomplete="email"
                        :error="
                            getError('email') || getError('recaptcha_token')
                        "
                        @blur="v$.form.email.$touch()"
                    />
                </div>

                <div class="mt-6">
                    <TextInput
                        id="password"
                        v-model="state.form.password"
                        type="password"
                        required
                        suppress-required-icon
                        autocomplete="new-password"
                        name="new-password"
                        label="Set new password"
                        placeholder="Set your new password"
                        :error="getError('password')"
                        @blur="v$.form.password.$touch()"
                    />
                </div>

                <div class="mt-6">
                    <TextInput
                        id="password_confirmation"
                        v-model="state.form.password_confirmation"
                        type="password"
                        required
                        suppress-required-icon
                        autocomplete="new-password"
                        name="new-password-confirmation"
                        label="Confirm password"
                        placeholder="Your new password again, please"
                        :error="getError('password_confirmation')"
                        @blur="v$.form.password_confirmation.$touch()"
                    />
                </div>

                <div class="mt-10 flex flex-col items-center">
                    <MegaButton :disabled="loading" type="submit"
                        >Confirm new password
                    </MegaButton>

                    <inertia-link
                        :href="route('login')"
                        class="mt-6 pb-2 text-xs font-medium text-custom-blue-500 hover:text-custom-blue-600"
                        >Cancel reseting your password</inertia-link
                    >
                </div>
            </form>
        </div>
    </AuthLayout>
</template>

<script lang="ts" setup>
import { computed, reactive, ref } from "vue";
import route from "ziggy-js";
import {
    required,
    email as emailRule,
    minLength,
    maxLength,
    helpers,
    sameAs,
} from "@vuelidate/validators";
import { useReCaptcha } from "vue-recaptcha-v3";
import useVuelidate from "@vuelidate/core";
import { router } from "@inertiajs/vue3";
import IInputErrors from "@/types/Atomic/IInputErrors";

const props = defineProps({
    email: {
        type: String,
        required: true,
    },
    token: {
        type: String,
        required: true,
    },
});

const reCaptcha = useReCaptcha();

const state = reactive({
    form: {
        email: props.email,
        password: "",
        password_confirmation: "",
        token: props.token,
        recaptcha_token: "",
    },
});
const loading = ref(false);
const errors = ref<IInputErrors>({});
const passwordRef = computed(() => state.form.password);

const rules = {
    form: {
        email: { required, emailRule, maxLength: maxLength(255) },
        password: { required, minLength: minLength(8) },
        password_confirmation: {
            required,
            minLength: minLength(8),
            sameAsPassword: helpers.withMessage(
                "Passwords do not match",
                sameAs(passwordRef)
            ),
        },
    },
};

const v$ = useVuelidate(rules, state);

const submit = async () => {
    v$.value.form.$touch();
    if (v$.value.form.$error) {
        () => undefined;
    } else {
        try {
            loading.value = true;
            let token = undefined;

            try {
                await reCaptcha?.recaptchaLoaded();
                token = await reCaptcha?.executeRecaptcha("resetPassword");

                if (token === undefined) {
                    throw new Error();
                }
            } catch (e) {
                loading.value = false;
                errors.value = {
                    recaptcha_token: "ReCaptcha validation failed.",
                };
                return;
            }
            state.form.recaptcha_token = token;

            router.post(route("password.update"), state.form, {
                preserveScroll: true,
                onError: (err) => {
                    errors.value = err;
                },
                onFinish: () => {
                    loading.value = false;
                    v$.value.$reset();
                },
            });
        } catch (e) {
            throw new Error(JSON.stringify(e));
        }
    }
};

const getError = (field: string) => {
    return (
        v$.value.form[field]?.$errors[0]?.$message ||
        errors.value?.[field] ||
        ""
    );
};
</script>
