<template>
    <AuthLayout>
        <div
            class="mt-8 flex w-full flex-col items-center overflow-hidden bg-white p-6 shadow-xl sm:mt-14 sm:max-w-md sm:rounded-lg"
        >
            <div class="-mt-1 h-[240px] w-[240px]">
                <img
                    src="https://d5aoblv5p04cg.cloudfront.net/application/images/auth/forgotten-email@2x.png"
                    class="ml-[10px] mt-[10px] h-full w-full"
                    alt="MailClocker Illustration"
                />
            </div>

            <div class="mt-[14px] text-xl font-bold text-gray-800">
                Check your inbox!
            </div>

            <div
                class="mb-[31px] mt-3 max-w-sm text-center text-sm text-gray-500"
            >
                We’ve sent you instructions how to reset your password.
            </div>
        </div>
    </AuthLayout>
</template>

<script lang="ts" setup></script>
