<template>
    <AuthLayout>
        <div
            class="mt-8 flex w-full flex-col items-center overflow-hidden bg-white p-6 shadow-xl sm:mt-14 sm:max-w-md sm:rounded-lg"
        >
            <div class="-mt-1 h-[240px] w-[240px]">
                <img
                    src="https://d5aoblv5p04cg.cloudfront.net/application/images/auth/registration-email.png"
                    class="ml-[10px] mt-[10px] h-full w-full"
                    alt="MailClocker Illustration"
                />
            </div>

            <div class="mt-[14px] text-xl font-bold text-gray-800">
                Thank you for signing up!
            </div>

            <div
                class="mt-3 max-w-[360px] text-center text-sm font-medium text-gray-800"
            >
                Before getting started, please verify your email address by
                clicking on the link we’ve just emailed you.
            </div>

            <div class="mt-5 max-w-[260px] text-center text-sm text-gray-500">
                If you didn’t received verification email, we’ll send you
                another one.
            </div>

            <div
                v-if="verificationLinkSent"
                class="-mb-2 mt-5 text-center text-sm font-medium text-green-600"
            >
                A new verification link has been sent to the email address you
                provided during registration.
            </div>

            <form @submit.prevent="submit">
                <AtomicButton
                    class="mt-9"
                    :disabled="form.processing"
                    type="submit"
                >
                    Resend Verification Email
                </AtomicButton>
                <div class="mb-2 flex justify-center">
                    <inertia-link
                        :href="route('logout')"
                        method="post"
                        as="button"
                        class="mt-6 pb-2 text-xs font-medium text-custom-blue-500 hover:text-custom-blue-600"
                        >Log Out</inertia-link
                    >
                </div>
            </form>
        </div>
    </AuthLayout>
</template>

<script setup lang="ts">
import route from "ziggy-js";
import { useForm } from "@inertiajs/vue3";

const props = defineProps({
    status: {
        type: String,
        default: "",
    },
});

const form = useForm({});

const submit = async () => {
    form.post(route("verification.send"));
};

const verificationLinkSent = computed(() => {
    return props.status === "verification-link-sent";
});
</script>
