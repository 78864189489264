<template>
    <MemberTabs tab-key="members">
        <div class="mt-12 pb-80">
            <DataTable
                class="mt-10"
                :title="'Your teams active members' + ' (' + memberLength + ')'"
                :headers="headers"
                :items="members"
                :filters="filters"
                searchable
            >
                <template #item-profile_photo_url="{ data, row }">
                    <div class="flex justify-center">
                        <div
                            class="h-10 w-10 cursor-pointer rounded-full ring-[1px] ring-gray-300"
                        >
                            <img
                                :src="data ? data : row.profile_photo_url"
                                class="h-10 w-10 rounded-full object-cover"
                            />
                        </div>
                    </div>
                </template>

                <template #item-name="{ data, row }">
                    <div class="px-6 text-gray-800">{{ data }}</div>
                </template>

                <template #item-created_at="{ data }">
                    <div class="px-6 text-gray-500">
                        {{ format(new Date(data as string), "d. L. yyyy") }}
                    </div>
                </template>

                <template #item-role="{ data, row }">
                    <div class="px-6">
                        <TableMembersDropdown
                            v-if="(isOwner || isAdmin) && data !== 'owner'"
                            :selected="selectedRole(data as string)"
                            :items="list"
                            @select="updateRole"
                            @click="selectMember(row as IUser)"
                        />

                        <div v-else class="cursor-pointer">
                            <div>
                                <button
                                    type="button"
                                    class="flex h-10 min-w-[160px] items-center justify-between rounded-md border border-gray-300 bg-white px-2 py-3 text-sm font-medium text-gray-600 transition duration-100 ease-in-out hover:text-gray-800 hover:shadow-button focus:text-gray-800 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-gray-300 disabled:text-white"
                                >
                                    <p>
                                        {{
                                            data.charAt(0).toUpperCase() +
                                            data.slice(1)
                                        }}
                                    </p>
                                </button>
                            </div>
                        </div>
                    </div>
                </template>

                <template #item-custom="{ row }">
                    <div>
                        <AtomicButton
                            v-if="isOwner && row.role !== 'owner'"
                            class="mx-4"
                            size="sm"
                            color="white"
                            @click="removeMemberModalShow(row as IUser)"
                        >
                            <template #icon>
                                <TrashIcon class="h-5 w-5 text-gray-400" />
                            </template>
                        </AtomicButton>
                    </div>
                </template>
            </DataTable>

            <RemoveMemberModal
                v-if="removeMemberModal"
                :member="selectedMember"
                :team="teamProps"
                @keydown.esc="removeMemberModal = false"
                @remove="removeMember"
                @close="removeMemberModal = false"
            />
        </div>
    </MemberTabs>
</template>

<script lang="ts" setup>
import { format } from "date-fns";
import { TrashIcon } from "@heroicons/vue/24/outline";
import route from "ziggy-js";
import { router } from "@inertiajs/vue3";
import { ref } from "vue";
import IUser from "@/types/Members/IUser";
import { create } from "@composables/useNotifications";
import IPaginationData from "@/components/Atomic/DataTable/types/IPaginationData";
import IDataTableHeader from "@/components/Atomic/DataTable/types/IDataTableHeader";

import usePermissions from "@/composables/usePermissions";
import { usePage } from "@inertiajs/vue3";
import { IMailClockerPageProps } from "@/types/MailClockerPageProps/MailClockerPageProps";
import { IDataTableFilter } from "@/components/Atomic/DataTable/types/IDataTableFilter";

import ICurrentTeam from "@/types/Members/ICurrentTeam";

const props = defineProps<{
    members: IPaginationData<IUser>;
}>();

const selectedMember = ref<IUser>();
const { isAdmin, isOwner } = usePermissions();
const removeMemberModal = ref(false);
const globalProps = computed(() => usePage<IMailClockerPageProps>().props);

const teamProps = computed(() => globalProps.value.team as ICurrentTeam);

const memberLength = computed(() => props.members.data.length);
const { getCurrentTeamMembersCount } = useTeamsAndMembers();

const headers: IDataTableHeader[] = [
    { text: "", id: "profile_photo_url" },
    {
        text: "Name",
        id: "name",
        sortable: true,
        sortableId: "name",
    },
    {
        text: "Email",
        id: "email",
        sortable: true,
        sortableId: "email",
    },
    {
        text: "Joined",
        id: "created_at",
        sortable: true,
        sortableId: "team_user.created_at",
    },
    {
        text: "Permissions / Role",
        id: "role",
        sortable: true,
        sortableId: "role",
    },
    { text: "", id: "custom" },
];

const filters: IDataTableFilter[] = [
    {
        column: "role",
        label: "Member role",
        values: [
            {
                label: "Owners",
                value: "owner",
            },
            {
                label: "Admins",
                value: "admin",
            },
            {
                label: "Editors",
                value: "editor",
            },
            {
                label: "Viewers",
                value: "viewer",
            },
        ],
    },
];

const list = [
    // {
    //     title: 'Team owner',
    //     subtitle:
    //         'Only team owner can transfer team ownership to other team member.',
    //     value: 'owner',
    // },
    {
        title: "Administrator",
        subtitle:
            "Can invite or remove team members and change member’s rights.",
        value: "admin",
    },
    {
        title: "Editor",
        subtitle:
            "Can perform all actions but can’t invite or remove team members or change their rights.",
        value: "editor",
    },
    {
        title: "Viewer",
        subtitle: "Can only view team.",
        value: "viewer",
    },
];

const selectedRole = (value: string) => {
    const a = list.filter((a) => a.value === value);
    const b = Object.assign({}, ...a);
    return b;
};

const selectMember = (value: IUser) => {
    selectedMember.value = value;
};

const updateRole = (value: string) => {
    const a = list.find((a) => a.value === value);
    if (a && selectedMember.value) {
        router.put(
            route("members.update", {
                member: selectedMember.value.id,
            }),
            {
                role: a.value,
            },
            {
                preserveScroll: true,
                onSuccess: () => {
                    create({
                        title: "Team member updated",
                        type: "success",
                    });
                },
                onError: () => {
                    create({
                        title: "Team member not updated",
                        text: "There was an error updating your team member.",
                        type: "error",
                    });
                },
            }
        );
    }
};
const removeMemberModalShow = (member: IUser) => {
    selectedMember.value = member;
    removeMemberModal.value = true;
};
const removeMember = async () => {
    if (selectedMember.value) {
        router.delete(
            route("members.delete", {
                member: selectedMember.value.id,
            }),
            {
                preserveScroll: true,
                onSuccess: () => {
                    getCurrentTeamMembersCount();
                    create({
                        title: "Team member removed",
                        type: "success",
                    });
                },
                onError: () => {
                    create({
                        title: "Team member not removed",
                        text: "There was an error removing your team member.",
                        type: "error",
                    });
                },
                onFinish: () => {
                    removeMemberModal.value = false;
                },
            }
        );
    }
};
</script>

<style scoped></style>
