import { ref } from "vue";

const opened = ref(false);
const confirmDialogData = ref<IConfirmData>();
import IConfirmData from "@/types/Atomic/IConfirmData";
import Events from "@/types/Emitter/Events";
import mitt from "mitt";

const emitter = mitt<Events>();

export default () => {
    const show = (data: IConfirmData): Promise<boolean | string> => {
        if (data.type === undefined) {
            data.type = "info";
        }

        confirmDialogData.value = data;
        opened.value = true;

        return new Promise((resolve, reject) => {
            if (emitter === undefined) {
                reject("Emitter is not defined!");
                return;
            }

            emitter.on("dialog-confirmed", (inputData?: string) => {
                opened.value = false;

                if (inputData !== "" && inputData !== undefined) {
                    resolve(inputData);
                }

                resolve(true);
            });

            emitter.on("dialog-canceled", () => {
                opened.value = false;
                resolve(false);
            });
        });
    };

    return {
        opened,
        emitter,
        confirmDialogData,
        show,
    };
};
