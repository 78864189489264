<template>
    <div>
        <Modal size="md" @close="$emit('close')">
            <template #header>
                <div class="text-gray-600">Create new team</div>
            </template>
            <template #body>
                <form class="mb-6 px-6" @submit.prevent="createTeam">
                    <TextInput
                        v-model="v$.name.$model"
                        class="mt-5"
                        placeholder="Enter your team name"
                        label="Team name"
                        :error="getError('name')"
                        required
                        autofocus
                    />

                    <div class="mt-6 text-sm font-medium text-gray-700">
                        Team profile photo
                        <span class="font-normal text-gray-500"
                            >(optional)</span
                        >
                    </div>

                    <div class="mt-2 flex flex-wrap items-center">
                        <div
                            class="mr-5 h-[72px] w-[72px] overflow-hidden rounded-full border border-gray-300"
                        >
                            <img
                                :src="
                                    img
                                        ? imgUrl
                                        : 'https://d5aoblv5p04cg.cloudfront.net/application/images/profile-blank.png'
                                "
                                class="h-[72px] w-[72px] rounded-full object-cover"
                            />
                        </div>

                        <div class="relative">
                            <AtomicButton
                                class="mr-4 mt-5 sm:mt-0"
                                color="white"
                                size="md"
                                @click="clickToUpload"
                            >
                                Upload profile photo
                            </AtomicButton>
                            <input
                                id="imageUpload"
                                ref="file"
                                type="file"
                                accept="image/*"
                                class="absolute block opacity-0"
                                @change="addPhoto"
                            />
                        </div>

                        <button
                            v-if="img"
                            type="button"
                            class="mr-4 mt-5 flex pl-2 focus:outline-none sm:mr-0 sm:mt-0"
                            @click="removePhoto"
                        >
                            <svg
                                class="text-gray-400"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M4.29303 4.29302C4.48056 4.10555 4.73487 4.00023 5.00003 4.00023C5.26519 4.00023 5.5195 4.10555 5.70703 4.29302L10 8.58602L14.293 4.29302C14.3853 4.19751 14.4956 4.12133 14.6176 4.06892C14.7396 4.01651 14.8709 3.98892 15.0036 3.98777C15.1364 3.98662 15.2681 4.01192 15.391 4.0622C15.5139 4.11248 15.6255 4.18673 15.7194 4.28062C15.8133 4.37452 15.8876 4.48617 15.9379 4.60907C15.9881 4.73196 16.0134 4.86364 16.0123 4.99642C16.0111 5.1292 15.9835 5.26042 15.9311 5.38242C15.8787 5.50443 15.8025 5.61477 15.707 5.70702L11.414 10L15.707 14.293C15.8892 14.4816 15.99 14.7342 15.9877 14.9964C15.9854 15.2586 15.8803 15.5094 15.6948 15.6948C15.5094 15.8802 15.2586 15.9854 14.9964 15.9877C14.7342 15.99 14.4816 15.8892 14.293 15.707L10 11.414L5.70703 15.707C5.51843 15.8892 5.26583 15.99 5.00363 15.9877C4.74143 15.9854 4.49062 15.8802 4.30521 15.6948C4.1198 15.5094 4.01463 15.2586 4.01236 14.9964C4.01008 14.7342 4.11087 14.4816 4.29303 14.293L8.58603 10L4.29303 5.70702C4.10556 5.51949 4.00024 5.26518 4.00024 5.00002C4.00024 4.73486 4.10556 4.48055 4.29303 4.29302Z"
                                    fill="currentColor"
                                />
                            </svg>
                            <p class="pl-2 text-sm font-medium text-gray-600">
                                Remove photo
                            </p>
                        </button>
                    </div>
                </form>
            </template>

            <template #footer>
                <div class="flex justify-between border-t border-gray-200 p-6">
                    <AtomicButton
                        color="white"
                        size="md"
                        @click="$emit('close')"
                    >
                        Cancel
                    </AtomicButton>
                    <AtomicButton
                        :disabled="loading || v$.$invalid"
                        size="md"
                        color="yellow"
                        @click="createTeam"
                    >
                        Create team
                    </AtomicButton>
                </div>
            </template>
        </Modal>
    </div>
</template>

<script lang="ts" setup>
import useVuelidate from "@vuelidate/core";
import { required, maxLength } from "@vuelidate/validators";
import { router } from "@inertiajs/vue3";
import { create } from "@/composables/useNotifications";
import route from "ziggy-js";
import axios, { AxiosError } from "axios";

const emit = defineEmits([
    "close",
    "create",
    "update:name",
    "closeAndSwitchToTeam",
]);

const state = reactive({
    name: "",
});

const rules = {
    name: {
        required,
        maxLength: maxLength(255),
    },
};

const v$ = useVuelidate(rules, state);

const imgUrl = ref("");
const img = ref<File | null>(null);
const file = ref(null);

const loading = ref(false);
const errors = ref<Record<string, string> | null>(null);

const addPhoto = () => {
    //@ts-expect-error

    img.value = file.value.files[0] as File;
    imgUrl.value = URL.createObjectURL(img.value);
};

const removePhoto = () => {
    const input = document.querySelector("#imageUpload") as HTMLInputElement;

    if (input !== null) {
        input.value = "";
    }
    (img.value = null), (imgUrl.value = "");
};

const createTeam = async () => {
    v$.value.$touch();

    if (v$.value.name.$error) {
        () => undefined;
    } else {
        loading.value = true;

        const formData = new FormData();
        if (img.value) {
            formData.append("photo", img.value);
        }
        formData.append("name", state.name);

        try {
            loading.value = true;
            await axios.post(route("teams.store"), formData);

            emit("closeAndSwitchToTeam");
            router.get(route("home"));
            create({
                title: "Team created",
                text: "Your team has been successfully created.",
                type: "success",
            });
        } catch (err) {
            if (err instanceof AxiosError) {
                errors.value = err.response?.data.errors;
            }
        } finally {
            loading.value = false;
        }
    }
};

const clickToUpload = () => {
    const element = file.value as HTMLInputElement;
    element.click();
};

const getError = (field: string) => {
    return v$.value[field]?.$errors[0]?.$message || "";
};
</script>

<style></style>
