<template>
    <div class="flex w-full justify-center bg-gray-100">
        <div
            class="relative flex min-h-screen w-full max-w-screen-2xl flex-col items-center py-6 sm:justify-center"
        >
            <div>
                <a
                    :href="globalProps.homepage_url"
                    target="_blank"
                    class="flex flex-col items-center gap-2"
                >
                    <PrimaryLogo height="50" width="50" />
                    <span
                        class="text-2xl tracking-widest"
                        :style="{ 'font-family': 'teko' }"
                        >MAILCLOCKER</span
                    >
                </a>
            </div>

            <div class="right-[20px] top-[20px] mt-5 sm:absolute sm:mt-0">
                <a :href="globalProps.homepage_url" target="_blank">
                    <AtomicButton size="md" color="yellow">
                        Go to homepage
                    </AtomicButton>
                </a>
            </div>

            <slot />
        </div>
    </div>
</template>

<script setup lang="ts">
import { IMailClockerPageProps } from "@/types/MailClockerPageProps/MailClockerPageProps";

const globalProps = computed(() => usePage<IMailClockerPageProps>().props);
</script>
