<template>
    <div class="py-12">
        <h1
            class="text-2xl font-medium leading-normal tracking-small text-gray-800"
        >
            Welcome, {{ userProps.name }}!
        </h1>
    </div>
</template>

<script lang="ts" setup>
import IUser from "@/types/Members/IUser";
import { IMailClockerPageProps } from "@/types/MailClockerPageProps/MailClockerPageProps";

const globalProps = computed(() => usePage<IMailClockerPageProps>().props);
const userProps = computed(() => globalProps.value.user as IUser);
</script>
