<template>
    <div
        class="relative hidden min-h-screen w-16 shrink-0 flex-col overflow-visible border-r border-gray-200 bg-white pt-5 md:flex xl:w-56"
        :class="{ 'z-30': opened }"
    >
        <transition
            enter-active-class="transition duration-200 ease-out transform"
            enter-from-class="-translate-x-10 opacity-0 "
            enter-to-class="translate-x-0 opacity-100 "
            leave-active-class="transition duration-100 ease-in transform"
            leave-from-class="translate-x-0 opacity-100"
            leave-to-class="-translate-x-10 opacity-0"
        >
            <div
                v-if="opened"
                class="hidden xl:hidden"
                :class="{
                    'absolute left-[63px] top-0 z-[-2] h-full w-[161px] border-r border-gray-200 bg-white md:block':
                        opened,
                }"
            ></div>
        </transition>

        <div class="z-20 flex grow flex-col pt-[24px]">
            <nav
                class="w-16 flex-1 space-y-1 px-3 transition-[width] duration-200 xl:w-56 xl:px-4"
                aria-label="Sidebar"
                role="navigation"
                :class="{ 'absolute w-56 bg-none': opened }"
            >
                <div class="relative hidden h-10 md:block xl:hidden">
                    <transition
                        enter-active-class="transition ease-in-out duration-800"
                        enter-from-class="transform scale-0 opacity-0"
                        enter-to-class="transform scale-100 opacity-100"
                        leave-active-class="transition duration-100 ease-in "
                        leave-from-class="opacity-100 "
                        leave-to-class="opacity-0 "
                    >
                        <button
                            v-if="!opened"
                            class="absolute mx-2 mb-[16px] h-6 w-6 text-gray-600 xl:hidden"
                            @click="$emit('open')"
                        >
                            <Bars3BottomLeftIcon />
                        </button>
                    </transition>

                    <transition
                        enter-active-class="transition duration-1000 ease-in-out"
                        enter-from-class="transform rotate-180 opacity-0 scale-80"
                        enter-to-class="transform scale-100 rotate-0 opacity-100"
                        leave-active-class="transition duration-75 ease-in "
                        leave-from-class="opacity-100 "
                        leave-to-class="opacity-0 "
                    >
                        <button
                            v-if="opened"
                            class="absolute mx-2 mb-[16px] h-6 w-6 text-gray-600 xl:hidden"
                            @click="$emit('close')"
                        >
                            <XMarkIcon />
                        </button>
                    </transition>
                </div>
                <inertia-link
                    v-for="link in links"
                    :key="link.href"
                    v-tooltip="{
                        content: link.name,
                        delay: {
                            show: 1250,
                            hide: 150,
                        },
                    }"
                    :href="link.href"
                    :aria-current="page.startsWith(link.id) ? 'page' : null"
                    :class="{
                        'bg-gray-200 text-gray-600': page.startsWith(link.id),
                        'text-gray-500': !page.startsWith(link.id),
                        'w-auto': opened,
                        'w-10 ': !opened,
                    }"
                    class="group flex h-10 items-center justify-between rounded-md px-2 py-2 text-sm font-medium transition-all duration-200 hover:bg-gray-200 hover:text-gray-600 xl:w-auto"
                    @click="$emit('close')"
                >
                    <div
                        class="flex items-center transition duration-200 ease-in-out"
                        :data-helper-tooltip="link.name.toLocaleLowerCase()"
                    >
                        <component
                            :is="link.icon"
                            class="h-6 w-6 flex-shrink-0 transition duration-200 group-hover:text-gray-600 xl:mr-4"
                        ></component>

                        <transition
                            enter-active-class="transition duration-1000 ease-out transform"
                            enter-from-class="opacity-0 "
                            enter-to-class="opacity-100 "
                        >
                            <p
                                v-if="opened"
                                class="mx-4 hidden h-5 whitespace-nowrap md:block xl:hidden"
                            >
                                {{ link.name }}
                            </p>
                        </transition>

                        <p class="hidden xl:block">
                            {{ link.name }}
                        </p>
                    </div>
                    <div
                        v-if="link.count !== undefined"
                        class="hidden h-5 w-[37px] items-center justify-center rounded-2xl bg-gray-100 text-xs font-normal text-gray-500 xl:flex"
                    >
                        {{ link.count }}
                    </div>
                </inertia-link>
            </nav>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { Bars3BottomLeftIcon, XMarkIcon } from "@heroicons/vue/24/outline";

import { usePage } from "@inertiajs/vue3";
import { computed } from "vue";
import { IMailClockerPageProps } from "@/types/MailClockerPageProps/MailClockerPageProps";

defineProps({
    opened: {
        type: Boolean,
        default: false,
    },
});

defineEmits(["open", "close"]);

const { links } = sidebar();

const page = computed(() => usePage<IMailClockerPageProps>().url);
</script>

<style></style>
